"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mirrorCommand = mirrorCommand;
const commandfactory_1 = require("../map/commandfactory");
const segment_1 = require("../cad/segment");
const ltentity_1 = require("../cad/ltentity");
const ltfeature_1 = require("../data/ltfeature");
const drawutils_1 = require("./drawutils");
class MirrorCommandImpl {
    constructor(cadmap) {
        this._map = {};
        this._canvas = {};
        this.selectFeatures = [];
        this.entitys_clone = [];
        this._baseang = {};
        this._map = cadmap;
        this._canvas = this._map.canvas;
    }
    destroy() {
        this._map.trackGraphics.clear();
    }
    draws(entitys) {
        this._map.trackGraphics.clear();
        entitys.forEach(entity => {
            entity.worldDraw(this._canvas, true, null, this._map.trackGraphics);
        });
        return commandfactory_1.CommandStatus.eOK;
    }
    setBasePos(base) {
        this._baseang = base;
    }
    drawsMove(pos) {
        let line = new segment_1.RLine(this._baseang, pos);
        this.entitys_clone = [];
        this.selectFeatures.forEach(feature => {
            let geo = feature.getGeometry().clone();
            geo.data.mirror(line);
            this.entitys_clone.push(geo);
        });
        this.draws(this.entitys_clone);
    }
    save(pos) {
        let line = new segment_1.RLine(this._baseang, pos);
        this._map.undoStack().beginMacro("mirror");
        this.selectFeatures.forEach(feature => {
            var clone_ent = feature.getGeometry().clone();
            feature.getGeometry().data.mirror(line);
            this._map.updateFeatureGeometry(feature, clone_ent);
        });
        this._map.undoStack().endMacro();
        this._map.select_command.show();
    }
}
function* mirrorCommand(cadmap, param_map) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", cadmap, { comand_tip: "请选择镜像对象:" });
    let select_feature = yield;
    if (select_feature.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定镜像线第一点:");
    let base_point = yield;
    if (base_point.done === commandfactory_1.CommandStatus.eCancel || base_point.done === commandfactory_1.CommandStatus.eDone) {
        return base_point;
    }
    let command = new MirrorCommandImpl(cadmap);
    command.setBasePos(base_point.value);
    command.selectFeatures = select_feature.value;
    let base_line = new ltentity_1.LTLine(base_point.value, base_point.value);
    let snap_feature = new ltfeature_1.LTFeature({ geometry: new ltentity_1.LTEntityPoint(base_point.value) });
    cadmap.snap.setFeature(snap_feature);
    function moveCallback(mouse_event) {
        base_line.setEnd(mouse_event.mapPos);
        drawutils_1.DrawUtilImpl.drawDashLine(cadmap, base_line);
        command.drawsMove(mouse_event.mapPos);
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定镜像线第二点:", moveCallback, base_point.value);
    let angle = yield;
    if (angle.done === commandfactory_1.CommandStatus.eCancel || angle.done === commandfactory_1.CommandStatus.eDone) {
        command.destroy();
        drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, base_line);
        return { value: null, done: angle.done };
    }
    command.save(angle.value);
    command.destroy();
    drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, base_line);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
