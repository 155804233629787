"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectCommand = exports.SelectType = void 0;
exports.selectFeature = selectFeature;
const commandfactory_1 = require("../map/commandfactory");
const ltentity_js_1 = require("../cad/ltentity.js");
const ltpoint_js_1 = require("../cad/ltpoint.js");
const ltpoint_1 = require("../cad/ltpoint");
const ltutils_1 = require("../cad/ltutils");
//import * as PIXI from 'pixi.js'
var SelectType;
(function (SelectType) {
    SelectType[SelectType["eSelectNone"] = 0] = "eSelectNone";
    SelectType[SelectType["eSelectPoint"] = 1] = "eSelectPoint";
    SelectType[SelectType["eSelectBox"] = 2] = "eSelectBox";
    SelectType[SelectType["eSelectPointAndBox"] = 3] = "eSelectPointAndBox";
})(SelectType || (exports.SelectType = SelectType = {}));
;
class SelectCommand extends commandfactory_1.AbstractCommand {
    constructor(cadmap) {
        super();
        this._map = {};
        this.lineColor = 0;
        this.lineOpacity = 1;
        this.fillColor = 0;
        this._point_list = [];
        this.selectContainer = null;
        this._select_features = [];
        this._start_point = null;
        this._callback = null;
        this._select_type = SelectType.eSelectPointAndBox;
        //设置是否为连续选择;
        this._continue_select = false;
        //设置过滤图层;
        this._layer_filter = [];
        this._single_select_features = [];
        this.active(cadmap);
    }
    active(cadmap) {
        this._map = cadmap;
        this._select_box = null;
        this._point_list = [];
        this._init();
        this._select_features = [];
        return commandfactory_1.CommandStatus.eOK;
    }
    get layerFilter() {
        return this._layer_filter;
    }
    set layerFilter(value) {
        this._layer_filter = value;
    }
    get continueSelect() {
        return this._continue_select;
    }
    set continueSelect(value) {
        this._continue_select = value;
    }
    render() {
        this.show();
    }
    _init() {
        if (this.selectContainer == null) {
            this.selectContainer = new PIXI.Container();
            this._map.addGraphics(this.selectContainer, this._map.trackGraphics);
        }
        else {
            this.selectContainer.removeChildren();
        }
    }
    selectedFeatures() {
        return this._select_features;
    }
    clear() {
        if (this._select_box !== null) {
            this._map.trackGraphics.removeChild(this._select_box);
            this._select_box = null;
        }
    }
    setCallbacks(callback) {
        this._callback = callback;
    }
    callback() {
        return this._callback;
    }
    reset() {
        this._start_point = null;
        this.clearSelection();
    }
    clearSelection() {
        this._map.selectGraphics.clear();
        if (this._select_box !== null) {
            this._map.trackGraphics.removeChild(this._select_box);
            this._select_box = null;
        }
        this._select_features = [];
        if (this.selectContainer !== null) {
            this.selectContainer.removeChildren();
            this._map.trackGraphics.removeChild(this.selectContainer);
            this.selectContainer = null;
        }
        //取消选择;
        if (window.attriDataCallback != undefined) {
            window.attriDataCallback();
        }
    }
    isBoxSelecting() {
        return this._start_point !== null;
    }
    addSelectFeatures(feas) {
        this._select_features = feas;
    }
    destroy() {
        if (this._select_box != null) {
            this._map.trackGraphics.removeChild(this._select_box);
        }
    }
    drawLine(point_list, color) {
        if (point_list.length < 2) {
            return;
        }
        if (this._select_box == null) {
            this._select_box = new PIXI.smooth.SmoothGraphics();
            this._map.addGraphics(this._select_box, this._map.trackGraphics);
        }
        this._select_box.clear();
        this._select_box.beginFill(color, 0.3); //边框色
        for (var i = 0; i < point_list.length; ++i) {
            if (i == 0) {
                this._select_box.moveTo(point_list[i].x, point_list[i].y);
            }
            else {
                this._select_box.lineTo(point_list[i].x, point_list[i].y);
            }
        }
        this._select_box.endFill();
        const shader = new PIXI.smooth.DashLineShader({ dash: 2, gap: 2 });
        this._select_box.lineStyle({ width: 1, native: true, color: 0xffffff, shader });
        for (var i = 0; i < point_list.length; ++i) {
            if (i == 0) {
                this._select_box.moveTo(point_list[i].x, point_list[i].y);
            }
            else {
                this._select_box.lineTo(point_list[i].x, point_list[i].y);
            }
        }
        this._select_box.endFill();
    }
    onMouseDown(event) {
        return this.onMouseDown1(event.mapPos, event.shiftKey);
    }
    onMouseDown1(map_pos, is_shift = false) {
        {
            //第一次该坐标为空;
            if (this._start_point == null) {
                if (this._continue_select === false) {
                    this.clearSelection();
                }
                if (this._select_type & SelectType.eSelectPoint) {
                    if (this.selectFeaturesByPoints(map_pos, null, is_shift)) {
                        return commandfactory_1.CommandStatus.eOK;
                    }
                }
                if (this._select_type & SelectType.eSelectBox) {
                    if (this._continue_select === true) {
                        this._start_point = map_pos;
                    }
                    else {
                        if (this._select_features.length == 0) {
                            this._start_point = map_pos;
                        }
                    }
                }
            }
            else {
                this.selectFeaturesByPoints(this._start_point, map_pos, is_shift);
                this._start_point = null;
            }
            return commandfactory_1.CommandStatus.eOK;
        }
    }
    onMouseMove(event) {
        return this.onMouseMove1(event.mapPos);
    }
    onMouseMove1(map_pos) {
        if (this._start_point == null) {
            return commandfactory_1.CommandStatus.eOK;
        }
        var start_point = this._start_point;
        var move_point = map_pos;
        let line_color = 0x55ff55;
        if (move_point.x > start_point.x) {
            line_color = 0x5555ff;
        }
        let points = [
            start_point,
            new PIXI.Point(start_point.x, move_point.y),
            move_point,
            new PIXI.Point(move_point.x, start_point.y),
            start_point
        ];
        this.drawLine(points, line_color);
        return commandfactory_1.CommandStatus.eOK;
    }
    showVertex(map_pos, range) {
        this._vertex.beginFill(0x0000ff); //边框色
        this._vertex.moveTo(map_pos.x - range, map_pos.y - range);
        this._vertex.lineTo(map_pos.x + range, map_pos.y - range);
        this._vertex.lineTo(map_pos.x + range, map_pos.y + range);
        this._vertex.lineTo(map_pos.x - range, map_pos.y + range);
        this._vertex.lineTo(map_pos.x - range, map_pos.y - range);
        this._vertex.endFill();
    }
    showMiddle(map_pos, range, angle) {
        this._vertex.beginFill(0x0000ff); //边框色
        let new_range = range * 2;
        let base_point = map_pos.calculatePoint(angle, -new_range);
        let p1 = base_point.calculatePoint(angle - Math.PI / 2.0, range * 0.5);
        this._vertex.moveTo(p1.x, p1.y);
        let p2 = p1.calculatePoint(angle, 4 * range);
        this._vertex.lineTo(p2.x, p2.y);
        let p3 = p2.calculatePoint(angle + Math.PI / 2.0, range);
        this._vertex.lineTo(p3.x, p3.y);
        let p4 = p1.calculatePoint(angle + Math.PI / 2.0, range);
        this._vertex.lineTo(p4.x, p4.y);
        this._vertex.endFill();
    }
    replaceSelectFeature(old_feature, new_feature) {
        ltutils_1.LTUtils.replace(this._select_features, old_feature, new_feature);
        this.show();
    }
    show() {
        this._init();
        let features = this._select_features;
        this.selectContainer.removeChildren();
        this._map.selectGraphics.clear();
        this._vertex = new PIXI.Graphics();
        this.selectContainer.addChild(this._vertex);
        var range_distance = 6 / this._map.mapScale();
        let graphics_data = this._map.selectGraphics.geometry.graphicsData;
        for (let i = 0; i < features.length; i++) {
            let graphics = features[i].getGeometry()._graphicsData;
            if (graphics.length > 0) {
                graphics_data.push(...graphics);
            }
            let clip_graphics = features[i].getGeometry()._clip_graphics;
            if (clip_graphics != null && clip_graphics !== undefined) {
                graphics_data.push(...clip_graphics.geometry.graphicsData);
            }
        }
        this._map.selectGraphics.geometry.invalidate();
        if (features.length > 100) {
            return;
        }
        for (let i = 0; i < features.length; i++) {
            let points = features[i].getGeometry().data.getReferencePoints();
            for (let j = 0; j < points.length; j++) {
                if (points[j].flag == ltpoint_js_1.Flag.Secondary) {
                    //计算线的方向;
                    let angle = points[j].getAngleTo(points[j - 1]);
                    this.showMiddle(points[j], range_distance, angle);
                }
                else {
                    this.showVertex(points[j], range_distance);
                }
            }
        }
    }
    _addFeatures(fea) {
        if (fea.getGeometry().visible == false) {
            return;
        }
        if (this._callback === null) {
            if (this._select_features.indexOf(fea) === -1) {
                this._select_features.push(fea);
            }
            return;
        }
        if (this._select_features.indexOf(fea) === -1) {
            if (this._callback(fea)) {
                this._select_features.push(fea);
            }
        }
    }
    get singleSelectFeatures() {
        return this._single_select_features;
    }
    selectFeaturesByPoints(start_pos, map_pos = null, is_shift = false) {
        if (start_pos == null) {
            return false;
        }
        //选择
        if (map_pos == null) {
            map_pos = start_pos;
        }
        let result_features = [];
        let filter_layers = [];
        if (this._layer_filter.length == 0) {
            filter_layers = this._map.getLayers();
        }
        else {
            for (let layer_name of this._layer_filter) {
                let layer = this._map.getLayerByName(layer_name);
                if (layer != null) {
                    filter_layers.push(layer);
                }
            }
        }
        if (start_pos.x == map_pos.x && start_pos.y == map_pos.y) {
            let select_feature = this._map.selectSingleFeature(map_pos, this._callback, filter_layers);
            if (select_feature != null) {
                //判断位置
                if (select_feature.getGeometry().data.contains(map_pos) && select_feature.getGeometry().visible == true) {
                    result_features.push(select_feature);
                }
            }
            this._single_select_features = result_features;
        }
        else {
            let bounds = ltpoint_js_1.RBox.formVectors(start_pos, map_pos);
            let bounds_line = ltutils_1.LTUtils.boxToPolyline(bounds);
            const queryCallback = (fea) => {
                if (map_pos == null) {
                    return false;
                }
                if (fea.getGeometry().visible == false) {
                    return false;
                }
                if (map_pos.x > start_pos.x) {
                    if (bounds.contain(fea.getGeometry().getBoundingBox())) {
                        if (this._callback && !this._callback(fea)) {
                            return false;
                        }
                        return true;
                    }
                    return false;
                }
                if (bounds.contain(fea.getGeometry().getBoundingBox())) {
                    if (this._callback && !this._callback(fea)) {
                        return false;
                    }
                    return true;
                }
                if (fea.getGeometry().intersectsWith(bounds_line)) {
                    if (this._callback && !this._callback(fea)) {
                        return false;
                    }
                    return true;
                }
                return false;
            };
            result_features = this._map.selectFeatures(bounds, queryCallback, filter_layers);
        }
        this._single_select_features = result_features;
        this.selectFeatures(result_features, is_shift);
        if (result_features.length == 0) {
            return false;
        }
        return true;
    }
    selectFeatures(select_features, is_shift = false) {
        if (is_shift == false) {
            for (let val of select_features) {
                this._addFeatures(val);
            }
        }
        else {
            //清理已选择的;
            for (let val of select_features) {
                this._map.removeSelectGraphics(val.getGeometry());
                let index = this._select_features.indexOf(val);
                if (index >= 0) {
                    this._select_features.splice(index, 1);
                }
            }
        }
        this.show();
        if (this._select_features.length > 0) {
            if (window.attriDataCallback != undefined) {
                window.attriDataCallback();
            }
        }
        if (this._select_box != null) {
            this._select_box.clear();
        }
        return commandfactory_1.CommandStatus.eOK;
    }
}
exports.SelectCommand = SelectCommand;
function* selectFeature(map, command_param) {
    let callback = null;
    let layer_filter = [];
    let clear_selection = false;
    if (command_param !== null) {
        if (command_param.callback !== null && command_param.callback !== undefined) {
            callback = command_param.callback;
        }
        if (command_param.layerfilter !== null && command_param.layerfilter !== undefined) {
            layer_filter = command_param.layerfilter;
        }
        if (command_param.clear_selection !== null && command_param.clear_selection !== undefined) {
            clear_selection = command_param.clear_selection;
        }
    }
    if (map.selectedFeatures().length > 0) {
        let result_features = [];
        let map_features = map.selectedFeatures();
        if (callback !== null) {
            map_features.forEach(fea => {
                if (callback(fea)) {
                    result_features.push(fea);
                }
            });
        }
        else {
            result_features = map_features;
        }
        return { value: result_features, done: commandfactory_1.CommandStatus.eOK };
    }
    let command_tip = "请输入选择方式";
    let back_tip = "";
    if (command_param != null && command_param.command_tip != undefined) {
        command_tip = command_param.command_tip;
    }
    if (command_param != null && command_param.back == true) {
        back_tip = "返回上一级(2)";
    }
    let enum_select_type = SelectType.eSelectBox | SelectType.eSelectPoint;
    let select_feature = new SelectCommand(map);
    if (command_param != null) {
        if (command_param.continue_select != undefined) {
            select_feature.continueSelect = true;
        }
        if (command_param.select_type !== undefined) {
            select_feature._select_type = command_param.select_type;
        }
        else {
            select_feature._select_type = enum_select_type;
        }
        if (command_param.callback !== null && command_param.callback !== undefined) {
            select_feature.setCallbacks(command_param.callback);
        }
        if (command_param.layerfilter !== null && command_param.layerfilter !== undefined) {
            select_feature.layerFilter = command_param.layerfilter;
        }
    }
    map.select_command.clearSelection();
    while (true) {
        if (select_feature._select_type == SelectType.eSelectPoint) {
            map.cursorType = ltutils_1.MapCursorType.CursorSingleSelect;
        }
        commandfactory_1.CommandFactory.getInstance().getPoint(map, `${command_tip}[绘制选择(1) ${back_tip}]:`);
        let select_type = yield;
        if (select_type.done == commandfactory_1.CommandStatus.eCancel) {
            return { value: null, done: commandfactory_1.CommandStatus.eCancel };
        }
        else if (select_type.done == commandfactory_1.CommandStatus.eKeyWord) {
            if (select_type.value == "1") {
                //将参数写到命令里面的一种方式;
                let command_name = {
                    commandName: "pline",
                    paramMap: {
                        noSaveFeature: true,
                        is_draw_polygon: true
                    }
                };
                map.cursorType = ltutils_1.MapCursorType.CursorInCommand;
                commandfactory_1.CommandFactory.getInstance().execYieldCommand(JSON.stringify(command_name), map);
                let result = yield;
                if (result.done == commandfactory_1.CommandStatus.eCancel) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                let line = result.value;
                if (line instanceof ltentity_js_1.LTPolyline) {
                    line.subData().setClose();
                    var bounds = line.getBoundingBox();
                    let callbak = select_feature.callback();
                    function callback_filter(fea) {
                        if (fea.getGeometry().intersectsWith(line)) {
                            if (callbak && !callbak(fea)) {
                                return false;
                            }
                            return true;
                        }
                        return false;
                    }
                    select_feature.selectFeaturesByPoints(bounds.getMinimum(), bounds.getMaximum());
                    //判断
                    map.select_command.clearSelection();
                    map.select_command.selectFeatures(select_feature.selectedFeatures());
                    map.select_command.show();
                    if (command_param && command_param.renderCallback !== undefined) {
                        command_param.renderCallback(select_feature.selectedFeatures());
                    }
                    return { value: select_feature.selectedFeatures(), done: commandfactory_1.CommandStatus.eOK, points: line.subData().getEndPoints() };
                }
            }
            else if (select_type.value == "2" && command_param != null && command_param.back == true) {
                return { value: 2, done: commandfactory_1.CommandStatus.eKeyWord };
            }
        }
        else {
            //返回上一级
            if (select_type.done == commandfactory_1.CommandStatus.eCancel) {
                select_feature.clearSelection();
                select_feature.destroy();
                return { value: null, done: select_type.done };
            }
            if (select_type.done == commandfactory_1.CommandStatus.eDone) {
                let result = select_feature.selectedFeatures();
                map.select_command.clearSelection();
                select_feature.clearSelection();
                select_feature.destroy();
                return { value: result, done: commandfactory_1.CommandStatus.eDone };
            }
            let mouse_point = select_type.value;
            if (!(mouse_point instanceof ltpoint_1.LTPoint)) {
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            select_feature.onMouseDown1(mouse_point);
            if (command_param && command_param.renderCallback !== undefined) {
                command_param.renderCallback(select_feature.singleSelectFeatures);
            }
            let result_features = select_feature.selectedFeatures();
            let drawPoints = [mouse_point];
            if ((result_features.length == 0 && select_feature._select_type & SelectType.eSelectBox) || (select_feature._select_type == SelectType.eSelectBox)) {
                function drawCallback(mouse) {
                    select_feature.onMouseMove1(mouse.mapPos);
                }
                map.cursorType = ltutils_1.MapCursorType.CursorInCommand;
                commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定对角点:", drawCallback);
                let mouse2 = yield;
                if (mouse2.done == commandfactory_1.CommandStatus.eCancel) {
                    select_feature.clearSelection();
                    select_feature.destroy();
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                else if (mouse2.done === commandfactory_1.CommandStatus.eDone) {
                    let result = select_feature.selectedFeatures();
                    map.select_command.clearSelection();
                    select_feature.clearSelection();
                    select_feature.destroy();
                    return { value: result, done: commandfactory_1.CommandStatus.eDone };
                }
                if (!(mouse2.value instanceof ltpoint_1.LTPoint)) {
                    select_feature.clearSelection();
                    select_feature.destroy();
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                select_feature.onMouseDown1(mouse2.value);
                if (command_param && command_param.renderCallback !== undefined) {
                    command_param.renderCallback(select_feature.singleSelectFeatures);
                }
                result_features = select_feature.selectedFeatures();
                drawPoints.push(mouse2.value);
            }
            map.select_command.selectFeatures(result_features);
            map.select_command.show();
            if (!select_feature.continueSelect) {
                select_feature.clearSelection();
                select_feature.destroy();
                //单次选才保存此两个点;
                if (result_features.length > 0 && drawPoints.length > 1) {
                    //补全另外两个角点
                    drawPoints.splice(1, 0, new ltpoint_1.LTPoint(drawPoints[1].x, drawPoints[0].y));
                    drawPoints.splice(3, 0, new ltpoint_1.LTPoint(drawPoints[0].x, drawPoints[2].y));
                    drawPoints.push(drawPoints[0]);
                    return { value: result_features, done: commandfactory_1.CommandStatus.eOK, points: drawPoints };
                }
                if (clear_selection == true) {
                    map.select_command.clearSelection();
                }
                return { value: result_features, done: commandfactory_1.CommandStatus.eOK };
            }
        }
    }
}
