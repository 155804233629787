"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoinCommandImpl = void 0;
exports.joinCommand = joinCommand;
const ltentity_1 = require("../cad/ltentity");
const commandfactory_1 = require("../map/commandfactory");
class JoinCommandImpl {
    static autoConnect1(query_features, cadmap) {
        //将可以连接的对象连接起来,可能需要分组;
        let clone_entitys = [];
        for (let i = 0; i < query_features.length; ++i) {
            //起点坐标从小到大排序,以X轴;
            clone_entitys.push(query_features[i].getGeometry().clone());
        }
        clone_entitys.sort((ent1, ent2) => {
            let start_point1 = ent1.subData().getStartPoint();
            let start_point2 = ent2.subData().getStartPoint();
            if (start_point1.x < start_point2.x) {
                return -1;
            }
            else if (start_point1.x == start_point2.x) {
                if (start_point1.y < start_point2.y) {
                    return -1;
                }
                return 1;
            }
            return 1;
        });
        for (let i = 0; i < clone_entitys.length; ++i) {
            var ent1 = clone_entitys[i];
            if (ent1.isDelete === true) {
                continue;
            }
            for (let k = i + 1; k < clone_entitys.length; ++k) {
                var ent2 = clone_entitys[k];
                if (ent2.isDelete === true) {
                    continue;
                }
                let entity = this.join(ent1, ent2);
                if (entity !== null) {
                    clone_entitys[i] = entity;
                    ent2.isDelete = true;
                    ent1 = entity;
                    ent1.modify = true;
                }
            }
        }
        let has_modify = false;
        for (let i = 0; i < clone_entitys.length; ++i) {
            let feat = clone_entitys[i];
            if (feat == null) {
                continue;
            }
            if (feat.modify === true) {
                has_modify = true;
                break;
            }
        }
        if (has_modify) {
            //获取修改了的数据;
            cadmap.undoStack().beginMacro("join");
            for (let i = 0; i < clone_entitys.length; ++i) {
                let ent = clone_entitys[i];
                if (ent.modify === true) {
                    let clone_ent = query_features[i].getGeometry().clone();
                    query_features[i].setGeometry(ent);
                    cadmap.updateFeatureGeometry(query_features[i], clone_ent);
                }
                else if (ent.isDelete === true) {
                    cadmap.removeFeatures(query_features[i]);
                }
            }
            cadmap.undoStack().endMacro();
        }
    }
    static join(ent1, ent2) {
        let result_polyline = null;
        if (ent1 instanceof ltentity_1.LTPolyline) {
            result_polyline = ent1;
        }
        else if (ent1 instanceof ltentity_1.LTArc) {
            let points = [];
            points.push(ent1.subData().getStartPoint());
            points.push(ent1.subData().getEndPoint());
            result_polyline = new ltentity_1.LTPolyline(points);
            ent1.cloneTo(result_polyline);
            result_polyline.subData().setBulgeAt(0, ent1.subData().getBulge());
        }
        else if (ent1 instanceof ltentity_1.LTLine) {
            let points = [];
            points.push(ent1.subData().getStartPoint());
            points.push(ent1.subData().getEndPoint());
            result_polyline = new ltentity_1.LTPolyline(points);
            ent1.cloneTo(result_polyline);
        }
        if (result_polyline === null) {
            return null;
        }
        let sub1 = ent1.data;
        let sub2 = ent2.data;
        if (sub1.getEndPoint().getDistanceTo(sub2.getEndPoint()) < 1e-4) {
            //点进行反转处理;
            sub2.reverse();
        }
        else if (sub1.getEndPoint().getDistanceTo(sub2.getStartPoint()) < 1e-4) {
        }
        else {
            return null;
        }
        if (ent2 instanceof ltentity_1.LTPolyline) {
            let verices = ent2.subData().vertices;
            for (let i = 1; i < verices.length; ++i) {
                result_polyline.subData().appendVertex(verices[i], ent2.subData().getBulgeAt(i));
            }
        }
        else if (ent2 instanceof ltentity_1.LTLine) {
            result_polyline.subData().appendVertex(sub2.getEndPoint(), 0);
        }
        else if (ent2 instanceof ltentity_1.LTArc) {
            result_polyline.subData().appendVertex(sub2.getStartPoint(), ent2.subData().getBulge());
            result_polyline.subData().appendVertex(sub2.getEndPoint(), 0);
        }
        return result_polyline;
    }
}
exports.JoinCommandImpl = JoinCommandImpl;
function* joinCommand(cadmap, param_map) {
    let select_features = [];
    while (true) {
        commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", cadmap, { command_tip: "请选择一次性要合并的多次对象" });
        let input_select_features = yield;
        if (input_select_features.done == commandfactory_1.CommandStatus.eCancel || input_select_features.done == commandfactory_1.CommandStatus.eDone) {
            return input_select_features;
        }
        if (input_select_features.value.length < 2) {
            cadmap.printMessage("请选择两个以上的对象");
            continue;
        }
        select_features = input_select_features.value;
        break;
    }
    JoinCommandImpl.autoConnect1(select_features, cadmap);
}
