<template>
  <div class="common-container userCenter-container">
    <div class="common-content">
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane
          label="账户信息"
          name="first"
        >
          <div class="scroll-Y acount-box">
            <div class="userCenter-box">
              <div class="flex-center userCenter-left-box">
                <div class="user-avatar">
                  <img
                    v-if="loading"
                    :src="userAvatar"
                    alt=""
                  />
                </div>
                <div style="font-size:16px;font-weight:600;margin-top: 5px;">
                  {{ userData.nickName }}
                </div>
                <el-button
                  type="primary"
                  size="small"
                  style="margin-top:12px"
                  class="flex-center avatar-btn"
                  @click="choiceFile"
                >修改头像
                  <i class="el-icon-edit-outline"></i>
                </el-button>
                <input
                  type="file"
                  accept="image/*"
                  ref="filElem"
                  style="display:none;"
                  @change="picUpload($event)"
                />
              </div>
              <div class="userCenter-form-box">
                <div class="flex align-center user-title">
                  账号信息
                </div>
                <div class="flex form-main">
                  <div class="flex form-item">
                    <div class="form-label">昵称：</div>
                    <div
                      class="form-content"
                      style="width:56%;"
                    >
                      <el-input
                        v-model="userData.nickName"
                        placeholder="昵称"
                        @blur="onSave('nickName')"
                        @change="changeUserData"
                      ></el-input>
                    </div>
                  </div>
                  <div class="flex form-item">
                    <div class="form-label">邮箱：</div>
                    <div class="flex form-content">
                      <div
                        class="form-value"
                        v-if="userData.email"
                      >
                        <el-input
                          v-model="userData.email"
                          placeholder="邮箱"
                          readonly
                        ></el-input>
                      </div>
                      <div
                        class="flex-center form-btn"
                        @click="onBindingEmail"
                        :class="{'form-btn-error':!userData.email}"
                      >
                        {{ userData.bindEmail ? "修改" : "绑定" }}
                      </div>
                    </div>
                  </div>
                  <div class="flex form-item">
                    <div class="form-label">手机：</div>
                    <div class="flex form-content">
                      <div
                        class="form-value"
                        v-if="userData.phone"
                      >
                        <el-input
                          v-model="userData.phone"
                          placeholder="手机"
                          readonly
                        ></el-input>
                      </div>
                      <div
                        class="flex-center form-btn"
                        @click="onBingingPhone"
                        :class="{'form-btn-error':!userData.phone}"
                      >
                        {{ userData.bindPhone ? "修改" : "绑定" }}
                      </div>
                    </div>
                  </div>
                  <div class="flex form-item">
                    <div class="form-label">微信：</div>
                    <div class="flex form-content">
                      <div
                        class="form-value"
                        v-if="userData.wechatNickName"
                      >
                        <el-input
                          v-model="userData.wechatNickName"
                          placeholder="微信"
                          readonly
                        ></el-input>
                      </div>
                      <div
                        class="flex-center form-btn"
                        @click="onBingingWebChat('微信')"
                        :class="{'form-btn-error':!userData.wechatNickName}"
                      >
                        {{ userData.bindWechat ? "解绑" : "绑定" }}
                      </div>
                    </div>
                  </div>
                  <div class="flex form-item">
                    <div class="form-label">QQ：</div>
                    <div class="flex form-content">
                      <div
                        class="form-value"
                        v-if="userData.qqNickName"
                      >
                        <el-input
                          v-model="userData.qqNickName"
                          placeholder="QQ"
                          readonly
                        ></el-input>
                      </div>
                      <div
                        class="flex-center form-btn"
                        @click="onBingingQQ('QQ')"
                        :class="{'form-btn-error':!userData.qqNickName}"
                      >
                        {{ userData.bindQQ ? "解绑" : "绑定" }}
                      </div>
                    </div>
                  </div>
                  <div class="flex form-item">
                    <div class="form-label">城市：</div>
                    <div class="flex form-content">
                      <div
                        class="form-value"
                        v-if="address"
                      >
                        <el-input
                          v-model="address"
                          placeholder="城市"
                          readonly
                        ></el-input>
                      </div>
                      <div
                        class="flex-center form-btn"
                        @click="onAddress('修改')"
                        :class="{'form-btn-error':!address}"
                      >
                        修改
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="userCenter-form-box">
                <div class="flex align-center user-title">
                  社交信息
                </div>
                <div class="flex form-main">
                  <el-form
                    ref="form"
                    :model="userData"
                    size="small"
                    label-width="88px"
                  >
                    <el-form-item label="我的职业：">
                      <el-input
                        v-model="userData.job"
                        placeholder="请输入职业名称"
                        @blur="onSave"
                        @change="changeUserData"
                      />
                    </el-form-item>
                    <el-form-item label="我的公司：">
                      <el-input
                        v-model="userData.company"
                        placeholder="请输入公司名称"
                        @blur="onSave"
                        @change="changeUserData"
                      />
                    </el-form-item>
                    <el-form-item
                      label="个人描述："
                      style="margin-right:0;width:100%;"
                    >
                      <el-input
                        v-model="userData.description"
                        type="textarea"
                        resize="none"
                        :rows="5"
                        style="width:42%;"
                        @blur="onSave"
                        @change="changeUserData"
                      />
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <!-- <div class="flex-center edit-user-box">
              <el-button type="primary" @click="onSave">修改信息</el-button>
            </div> -->
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="会员信息"
          name="third"
        >
          <div class="flex-center userCenter-box member-box">
            <el-table
              :data="tableData"
              border
              style="width: 100%"
            >
              <el-table-column
                prop="date"
                label="加入时间"
              >
                <template slot-scope="scope">
                  {{ $formatDate(scope.row.createTime, "yyyy年MM月dd日") }}
                </template>
              </el-table-column>
              <el-table-column
                prop="dqDate"
                label="到期时间"
              >
                <template slot-scope="scope">
                  {{
                    packData.packageSimple &&
                    packData.packageSimple.packageType &&
                    packData.packageSimple.packageType == "FREE"
                      ? "--"
                      : $formatDate(scope.row.endTime, "yyyy年MM月dd日")
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="way"
                label="购买方式"
              >
                <template slot-scope="scope">
                  {{
                    isFreed(scope.row) || !scope.row.transactionNo
                      ? "线下购买"
                      : "线上购买"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="tc"
                label="套餐"
              >
                <template slot-scope="scope">
                  {{ scope.row.packageName }}
                  <template v-if="scope.row.billingCycleName">
                    -{{ scope.row.billingCycleName || "" }}
                  </template>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="账号升级"
          name="fourth"
        >
          <member ref="member"></member>
        </el-tab-pane>
      </el-tabs>
      <div class="flex-center userCenter-icon-box">
        <div class="flex-center icon-box">
          <img
            src="~images/home/icon_contact.png"
            alt=""
          />
          <div class="animated bounceIn contact-box">
            <div class="flex-center contact-top">
              联系我们
            </div>
            <div class="contact-main">
              <div class="contact-title">
                邮箱：<span>134526534@qq.com</span>
              </div>
              <div class="flex contact-content">
                <div class="flex-center contact-content-item">
                  <img
                    src="~images/home/ewm.png"
                    class="contact-img"
                    alt=""
                  />
                  <div class="flex-center contact-type">
                    <img
                      src="~images/home/zf_wx.png"
                      alt=""
                    />
                    微信登录
                  </div>
                </div>
                <div class="flex-center contact-content-item">
                  <img
                    src="~images/home/ewm.png"
                    class="contact-img"
                    alt=""
                  />
                  <div class="flex-center contact-type">
                    <img
                      src="~images/home/QQ.png"
                      alt=""
                    />
                    QQ登录
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex-center icon-box"
          @click="onshowAswer"
          v-if="getPackageSimpleByVal('feaQuestionAnswer')"
        >
          <img
            src="~images/home/icon_msg_edit.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <updateEmail
      ref="email"
      :supVue="sup_vue"
      @getUserInfo="getUserInfo"
    />
    <updatePhone
      :sup_vue="sup_vue"
      ref="phone"
      :phone="userData.phone"
    ></updatePhone>
    <unBinging ref="unBinging"></unBinging>
    <binging ref="binging"></binging>
    <addressBox
      :sup_vue="sup_vue"
      ref="address"
    ></addressBox>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="showDialog"
      :modal="true"
      :modal-append-to-body="false"
      title="意见反馈"
      width="600px"
      style="text-align:left"
      v-dialogDrag
    >
      <div
        class="scroll-Y form-wrapper"
        v-closeSelect
      >
        <el-form
          ref="form"
          :model="dialogData"
          :rules="rules"
          size="small"
          label-width="auto"
        >
          <!-- <el-form-item label="反馈标题" prop="title">
            <el-input v-model="dialogData.title" class="form-input" />
          </el-form-item> -->
          <!-- <el-form-item label="联系人" prop="contact">
            <el-input v-model="dialogData.contact" class="form-input" />
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input
              v-model="dialogData.phone"
              class="form-input"
              maxlength="11"
            />
          </el-form-item> -->
          <el-form-item
            prop="feedbackTypeId"
            label="意见类型"
            class="form-row"
          >
            <el-select
              v-model="dialogData.feedbackTypeId"
              style="margin-right: 10px;margin-bottom: 0px;width:100%;"
              filterable
              placeholder="请选择"
              @change="choseType"
              ref="refYjtype"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="内容"
            prop="content"
          >
            <el-input
              v-model="dialogData.content"
              placeholder="请输入内容"
              type="textarea"
              :rows="5"
              resize="none"
            ></el-input>
          </el-form-item>

          <el-form-item label="附件">
            <!-- <updateFile
              ref="updateFile"
              v-model="fileList"
              :length="length"
            ></updateFile> -->
            <el-upload
              ref="uploadmap"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="handleMapChange"
              class="avatar-uploader"
              action=""
            >
              <img
                v-if="dialogData.snapshot"
                :src="dialogData.snapshot"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon addImg"
              />
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="cancelDialog('showDialog')"
          size="nomal"
          native-type="button"
        >取消</el-button>
        <el-button
          @click="doSubmit"
          type="primary"
          size="nomal"
          :loading="loadingBtn"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import updateEmail from "./components/updateEmail";
import updatePhone from "./components/updatePhone";
import unBinging from "./components/unBinging";
import binging from "./components/binging";
import addressBox from "./components/address";
import member from "./components/member";
import { get, post, del1, del } from "@/api/index";
import { mapGetters } from "vuex";
export default {
  name: "userCenter",
  components: {
    updateEmail,
    updatePhone,
    unBinging,
    binging,
    addressBox,
    member
  },
  data () {
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else if (!this.$isvalidPhone(value)) {
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
      // }
    };
    return {
      loading: false,
      sup_vue: this,
      activeName: "first",
      userData: {
        addr: {
          address: "",
          cityCode: "",
          cityName: "",
          countyCode: "",
          countyName: "",
          provinceCode: "",
          provinceName: ""
        },
        avatarUrl: null,
        bindEmail: false,
        bindPhone: true,
        bindQQ: false,
        bindWechat: false,
        company: "",
        description: "",
        email: "",
        job: "",
        nickName: "",
        phone: "",
        qqNickName: "",
        wechatNickName: ""
      },
      tableData: [],
      //   专家答疑
      showDialog: false,
      loadingBtn: false,
      dialogData: {
        title: "",
        id: "",
        contact: "",
        phone: "",
        content: "",
        file: null,
        problemType: "",
        feedbackTypeId: "",
        status: 1,
        customerUserId: ""
      },
      fileraw: null,
      rules: {
        title: [{ required: true, message: "请输入反馈标题", trigger: "blur" }],
        contact: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        feedbackTypeId: [
          { required: true, message: "请选择问题类型", trigger: "change" }
        ],
        phone: [{ required: true, trigger: "blur", validator: validPhone }]
      },
      typeList: [],
      fileList: [],
      code: "",
      state: "",
      oldName: "",
      orderUid: "",
      length: 1,
      isSave: false
    };
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.code = to.query && to.query.code ? to.query.code : ""; // 'pm02_j6FTqjLJRWefdJmOfqyyEw'
        vm.state = to.query && to.query.state ? to.query.state : "";
        vm.orderUid = to.query && to.query.orderUid ? to.query.orderUid : '';

        if (vm.orderUid || vm.code || vm.state) {
          if (vm.orderUid) {
            vm.activeName = "fourth";
            vm.$nextTick(() => {
              vm.$store.dispatch("user/getUserVip");
              vm.$store.dispatch("user/getUserInfo");
              vm.$refs.member && vm.$refs.member.getOrderId(vm.orderUid);
            });
          }
          if (
            vm.state == "userCenter" &&
            vm.code &&
            vm.userData &&
            !vm.userData.bindQQ
          ) {
            post(vm.$setApi("/cus/bind/qq"), {
              code: vm.code,
              state: vm.state
            })
              .then(res => {
                if (res) {
                  if (vm.code) {
                    vm.$router.push({ query: {} }); // 删除页面参数
                  }
                  vm.getUserInfo();
                }
              })
              .catch(() => {
                if (vm.code) {
                  vm.$router.push({ query: {} }); // 删除页面参数
                }
              });
          } else if (
            vm.state == "WX" &&
            vm.code &&
            vm.userData &&
            !vm.userData.bindWechat
          ) {
            post(vm.$setApi("/cus/bind/wechat"), {
              code: vm.code,
              state: vm.state
            })
              .then(res => {
                if (res) {
                  vm.getUserInfo();
                  if (vm.code) {
                    vm.$router.push({ query: {} }); // 删除页面参数
                  }
                }
              })
              .catch(() => {
                if (vm.code) {
                  vm.$router.push({ query: {} }); // 删除页面参数
                }
              });
          }
        }
      })
    })
  },
  created () {
  },
  computed: {
    ...mapGetters(["userInfo", "packData"]),
    address () {
      let area = "";
      if (this.userData && this.userData.addr) {
        area +=
          this.userData.addr.provinceName +
          this.$setNull(this.userData.addr.cityName) +
          this.$setNull(this.userData.addr.countyName);
        // this.userData.addr.address;
      }
      return area;
    },
    userAvatar () {
      return this.userData && this.userData.avatarUrl
        ? this.userData.avatarUrl
        : require("images/avatar.png");
    }
  },
  mounted () {
    // this.getUserInfo();
    // this.initData();
    this.getTypeList()
  },
  activated () {
    this.loading = false;
    this.getUserInfo();
    this.initData();
  },
  methods: {
    getTypeList () {
      this.typeList = [];
      post(this.$setApi("/sys/feedback-type/page"), {
        pageNum: 0,
        pageSize: 999
      }).then(res => {
        res.data.records.map(item => {
          if (item.typeName.indexOf("专家") == -1) {
            this.typeList.push({
              id: item.id,
              name: item.typeName
            });
          }
        });
      }).catch((e) => {
      });
    },
    isFreed (it) {
      return (
        it.packageName.indexOf("免费") > -1 ||
        it.packageName.indexOf("体验") > -1
      );
    },
    initData () {
      this.tableData = [];
      get(this.$setApi("/cus/muser-package/list")).then(res => {
        if (res && res.data && res.data.length > 0) {
          res.data.map(item => {
            this.tableData.push(item);
          });

        }
      });
    },
    choiceFile () {
      this.$refs.filElem.dispatchEvent(new MouseEvent("click"));
    },
    // 上传文件
    picUpload (e) {
      if (
        /\.(jpeg|jpg|png|gif|bmp|svg|webp|webp|tif|ico)/.test(
          e.srcElement.files[0].name
        )
      ) {
        this.$set(
          this.userData,
          "avatarUrl",
          URL.createObjectURL(e.srcElement.files[0])
        );
        this.fileraw = e.srcElement.files[0];
        var postData = new FormData();
        if (this.fileraw) postData.append("avatar-file", this.fileraw);
        post(this.$setApi("/cus/user/info"), postData)
          .then(res => {
            if (res.data) {
              this.dialogProblem = false;
              this.$message.success("操作成功");
              this.resetForm();
              this.getUserInfo();
            }
          })
          .catch(() => {

            // this.$message.warning(err);
          });
      } else this.$message.warning("请上传图片格式文件");

    },
    getUserInfo () {
      this.loading = false;
      this.$store.dispatch("user/getUserInfo").then(res => {
        if (res) {
          for (const key in res) {
            this.userData[key] = "";
            if (res[key] != null && res[key] != undefined) {
              this.userData[key] = res[key];
            }
          }
          this.oldName = JSON.parse(JSON.stringify(res.nickName)) || "";
        }
        this.loading = true;

      });
    },
    handleClick () {
      // if (this.activeName === "fourth") this.$router.push("member");
    },
    unBinging (title) {
      this.$refs.unBinging.initData(title);
    },
    onBingingPhone () {
      this.$refs.phone.initData(
        (this.userData && this.userData.phone) || "",
        (this.userData && this.userData.bindPhone) || false
      );
      return;
      if (!this.userData.bindPhone) this.$refs.phone.dialog = true;
      else {
        this.$confirm(
          "确定要解绑手机号码吗？解绑后将无法通过手机号码登录单桌派系统？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            del1(this.$setApi("/cus/bind/phone"), {
              account: this.userData.phone,
              code: "",
              state: ""
            }).then(res => {
              this.getUserInfo();
            });
          })
          .catch(() => { });
      }
    },
    onBindingEmail () {
      this.$refs.email.bindEmail =
        (this.userData && this.userData.bindEmail) || false;
      this.$refs.email.form.email =
        (this.userData && this.userData.email) || "";
      this.$refs.email.dialog = true;
    },
    onBingingQQ () {
      if (!this.userData.bindQQ)
        window.location.href =
          "https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=102041349&state=userCenter&redirect_uri=https%3A%2F%2Fdendralink.com%2F%23%2Flogin";
      else {
        this.$confirm(
          "确定要解绑QQ吗？解绑后将无法通过QQ扫码登录单桌派系统？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            del(this.$setApi("/cus/bind/qq")).then(res => {
              if (res && res.data) {
                this.$message.success("QQ解绑成功");
                this.getUserInfo();
              }
            });
          })
          .catch(() => { });
      }
    },
    onBingingWebChat () {
      if (!this.userData.bindWechat)
        window.location.href =
          "https://open.weixin.qq.com/connect/qrconnect?appid=wx0ef76ac160550f8b&redirect_uri=https%3A%2F%2Fdendralink.com%2F%23%2FuserCenter&response_type=code&scope=snsapi_login&state=WX#wechat_redirect";
      else {
        this.$confirm(
          "确定要解绑微信吗？解绑后将无法通过微信扫码登录单桌派系统？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            del(this.$setApi("/cus/bind/wechat")).then(res => {
              if (res && res.data) {
                this.$message.success("微信解绑成功");
                this.getUserInfo();
              }
            });
          })
          .catch(() => { });
      }
    },
    onBinging (title) {
      this.$refs.binging.initData(title);
    },
    onAddress (title) {
      let address = this.userData.addr
        ? JSON.parse(JSON.stringify(this.userData.addr))
        : "";
      this.$refs.address.initData(title, address);
    },
    // 答疑
    choseType (val) {
      this.typeList.some(item => {
        if (item.id == val) {
          this.dialogData.problemType = item.name;
        }
      });
    },
    onshowAswer () {
      this.dialogData = {
        id: "",
        title: "",
        email: "",
        contact: "",
        phone: "",
        content: "",
        file: null,
        problemType: "",
        feedbackTypeId: "",
        fileraw: null,
        snapshot: "",
        status: 1,
        customerUserId: ""
      };
      if (this.userData && this.userData.id) {
        this.dialogData.customerUserId = this.userData.id || "";
        this.dialogData.contact = this.userData.nickName || "";
        this.dialogData.phone = this.userInfo.phone || "";
        this.dialogData.email = this.userInfo.email || "";
      }

      this.fileList = [];
      this.showDialog = true;
    },
    cancelDialog (type) {
      this[type] = false;
    },

    doSubmit () {
      this.validateForm("form", (valid, mess) => {
        if (valid) {
          var postData = new FormData();
          const data = {
            title: "",
            id: "",
            email: "",
            contact: "",
            phone: "",
            content: "",
            problemType: "",
            feedbackTypeId: "",
            status: 1,
            customerUserId: ""
          };
          for (var key in data) {
            postData.append(key, this.dialogData[key]);
          }
          if (this.dialogData.fileraw)
            postData.append("files", this.dialogData.fileraw);

          post(this.$setApi("/cus/feedback"), postData).then(res => {
            if (res) {
              this.$message({
                message: "反馈成功",
                type: "success"
              });
              this.showDialog = false;
            }
          });
        } else {
          this.$message.warning(mess[0].message);
        }
      });
    },
    handleMapChange (file) {
      // this.$set(this.problemData, "snapshot", URL.createObjectURL(file.raw));
      this.dialogData.fileraw = file.raw;

      if (this.$getFileType(file.name) == "image")
        this.$set(this.dialogData, "snapshot", URL.createObjectURL(file.raw));
      else
        this.$set(
          this.dialogData,
          "snapshot",
          require("@/assets/images/fileIcon/" +
            this.$getFileType(file.name) +
            ".png")
        );
    },
    uploadFileMap (file) {
      file;
    },
    validateForm (formName = "form", call) {
      const promises = [this.$refs[formName]];
      let count = 0;
      for (let index = 0; index < promises.length; index++) {
        const element = promises[index];
        element.validate((result, errors) => {
          if (result) {
            count++;
            if (count == promises.length) {
              call(true);
              return;
            }
          } else {
            const fkey = Object.keys(errors)[0];
            call(false, errors[fkey]);
            return;
          }
        });
      }
    },
    changeUserData () {
      this.isSave = true;
    },
    onSave (type = "") {
      if (!this.isSave) return;
      if (!this.userData.nickName) {
        this.$message.warning("昵称不能为空");
        this.getUserInfo();
        return;
      }
      // if (
      //   type == "nickName" &&
      //   this.oldName &&
      //   this.userData.nickName &&
      //   this.oldName == this.userData.nickName
      // ) {
      //   this.$message.warning("昵称重复");
      //   return;
      // }
      var postData = new FormData();
      const data = {
        bindEmail: "",
        bindPhone: "",
        bindQQ: "",
        bindWechat: 0,
        company: "",
        description: "",
        email: "",
        job: "",
        nickName: "",
        phone: "",
        qqNickName: "",
        wechatNickName: ""
      };
      for (var key in data) {
        postData.append(key, this.userData[key]);
      }

      if (this.fileraw) postData.append("avatar-file", this.fileraw);
      post(this.$setApi("/cus/user/info"), postData)
        .then(res => {
          if (res.data) {
            this.dialogProblem = false;
            this.$message.success("操作成功");
            this.resetForm();
            this.getUserInfo();
          }
        })
        .catch(err => { });
    },
    resetForm () {
      this.userData = {
        addr: {
          address: "",
          cityCode: "",
          cityName: "",
          countyCode: "",
          countyName: "",
          provinceCode: "",
          provinceName: ""
        },
        avatarUrl: null,
        bindEmail: false,
        bindPhone: true,
        bindQQ: false,
        bindWechat: false,
        company: "",
        description: "",
        email: "",
        job: "",
        nickName: "",
        phone: "",
        qqNickName: "",
        wechatNickName: ""
      };
      this.isSave = false;
    }
  }
};
</script>
<style lang="stylus" scoped>
.userCenter-container
  overflow-y hidden

  >>>.el-tabs
    width 100%
    height 100%

  >>>.el-tabs__header
    margin 0

  >>>.el-tabs__item
    font-size 16px
    font-weight 700

  >>>.el-tabs__content
    height calc(100% - 60px)

  >>>.el-tab-pane
    widht 100%
    height 100%

  .common-content
    position relative
    overflow hidden
    margin 24px auto
    height calc(100% - 46px)
    border-radius 8px
    background #fff

  .userCenter-icon-box
    position absolute
    bottom 40px
    right 24px
    flex-direction column
    z-index 100

    .icon-box
      position relative
      width 46px
      height 46px
      border-radius 50%
      background #fff
      cursor pointer
      box-shadow 0px 8px 20px 0px rgba(9, 43, 80, .2)

      &:first-child:hover
        .contact-box
          display block

      &:last-child
        margin-top 24px

      img
        width 22px

    .contact-box
      position absolute
      bottom 0px
      right 50px
      width 350px
      height 300px
      display none
      background #FFFFFF
      box-shadow 0px 8px 20px 0px rgba(9, 43, 80, .2)
      border-radius 8px
      z-index 100

      .contact-top
        height 40px
        padding 0 20px
        font-size 16px
        font-weight 600
        color #222
        justify-content flex-start
        background #f5f8ff

      .contact-main
        margin 30px 35px

      .contact-title
        font-size 12px
        color #999
        text-align center

        span
          font-size 14px
          color #222

      .contact-content
        margin-top 29px
        justify-content space-around

        .contact-content-item
          width 50%
          flex-direction column

          .contact-img
            width 120px
            height 120px

        .contact-type
          margin-top 11px
          font-size 12px
          color #999

          img
            width 20px
            margin-right 8px

  >>>.el-table .el-table__header-wrapper th.el-table__cell
    background #f6f6f6

  >>>.el-tabs__nav-scroll
    display flex
    justify-content center

  >>>.el-tabs__item
    height 60px
    line-height 70px

  .acount-box
    position absolute
    top 0
    left 0
    right 0
    bottom 0

  .userCenter-box
    margin 32px auto 0px auto
    width 800px

  .member-box
    margin 32px auto

  .userCenter-left-box
    flex-direction column

    .avatar-btn
      color #3478f7
      font-size 14px
      background transparent !important

      i
        font-size 18px

    .user-avatar
      overflow hidden
      width 80px
      height 80px
      margin-bottom 10px
      display flex
      align-items center
      border-radius 100%

      img
        width 100%
        height 100%

  .form-main
    margin 0 30px
    flex-wrap wrap

  // 表单样式
  .userCenter-form-box
    font-weight 400
    font-size 18px
    color #333

    .form-item
      margin-bottom 16px
      align-items center
      width 49%
      height 32px
      margin-right 2%

      &:nth-child(2n)
        margin-right 0%

    .form-label
      width 50px
      font-size 14px
      color #222

    .form-content
      width calc(100% - 50px)
      align-items center

      >>>.el-input
        width 100%

    .form-value
      width calc(100% - 108px)
      margin-right 20px

    .form-btn
      font-size 14px
      color #fff
      font-weight 400
      cursor pointer
      width 52px
      height 22px
      background #3478F7
      border-radius 4px

    .el-form
      display flex
      flex-wrap wrap

      >>>.el-form-item
        width 49%
        margin-right 2%

        &:nth-child(2n)
          margin-right 0%

.form-wrapper
  max-height 350px

  .el-form
    padding 0 12px

.user-title
  position relative
  height 34px
  margin 16px 0
  padding 16px
  font-size 20px
  font-weight 600
  color #3478F7

  &:before
    position absolute
    top 50%
    left 0
    content ''
    display block
    width 4px
    height 18px
    background #3478F7
    border-radius 13px
    transform translateY(-50%)

.edit-user-box
  margin-bottom 16px

  button
    width 15%
    font-size 16px
</style>
