"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bleakBlock = bleakBlock;
const ltentity_1 = require("../cad/ltentity");
const ltmath_1 = require("../cad/ltmath");
const ltpoint_1 = require("../cad/ltpoint");
const segment_1 = require("../cad/segment");
const ltfeature_1 = require("../data/ltfeature");
const commandfactory_1 = require("../map/commandfactory");
function* bleakBlock(map, param_map) {
    function blockCallback(fea) {
        let data = fea.getGeometry().data;
        if (data instanceof segment_1.RBlockReference) {
            return true;
        }
        return false;
    }
    let param = { command_tip: "请选择要打散的块对象:", callback: blockCallback };
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, param);
    let select_feature_command = yield;
    if (select_feature_command.done === commandfactory_1.CommandStatus.eCancel || select_feature_command.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let selectfeatures = select_feature_command.value;
    if (selectfeatures.length == 0) {
        map.printMessage("未选择对象，请选择要打散的块对象");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let service = map.dwgservice;
    if (service === null) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    map.undoStack().beginMacro("explode");
    selectfeatures.forEach(feature => {
        let data = feature.getGeometry();
        if (data instanceof ltentity_1.LTEntityPoint) {
            let block = service === null || service === void 0 ? void 0 : service.getBlock(data.blockName);
            if (block instanceof ltentity_1.LTBlockReference) {
                let p = data.subData().position;
                let rotate = data.subData().getRotate();
                let scale = data.subData().scaleFactors;
                block._entitys.forEach(shape => {
                    let shapeclone = shape.clone();
                    let blockclone = shapeclone.data instanceof segment_1.RBlockReference;
                    if (blockclone === true && scale.y < 0.0) {
                        // shapeclone.move(new LTPoint(-shapeclone.data.position.x, -shapeclone.data.position));
                        shapeclone.data.scale(scale, null);
                        shapeclone.data.rotate(-2 * shapeclone.data.getRotate(), shapeclone.data.position);
                        shapeclone.data.rotate(rotate, new ltpoint_1.LTPoint(0, 0));
                        shapeclone.move(new ltpoint_1.LTPoint(p.x, p.y));
                        var fea = new ltfeature_1.LTFeature({
                            geometry: shapeclone,
                            layer: map.getLayerByName(shape.getLayerName())
                        });
                        map.addFeature(fea);
                        return;
                    }
                    // shapeclone.move(new LTPoint(-shapeclone.data.position.x, -shapeclone.data.position));
                    if (ltmath_1.LTMath.fuzzyCompare(scale.x, scale.y, 0.0)) {
                        shapeclone.scale(new ltpoint_1.LTPoint(scale.x, scale.x), new ltpoint_1.LTPoint(0, 0));
                    }
                    else if (ltmath_1.LTMath.fuzzyCompare(scale.x, -scale.y, 0.0)) {
                        shapeclone.scale(new ltpoint_1.LTPoint(scale.x, scale.x), new ltpoint_1.LTPoint(0, 0));
                        shapeclone.data.mirror(new segment_1.RLine(new ltpoint_1.LTPoint(0, 0), ltpoint_1.LTPoint.createPolar(1.0, 0.0)));
                    }
                    else {
                        if (blockclone === false) {
                            shapeclone.data.scale(scale, new ltpoint_1.LTPoint(0, 0));
                        }
                    }
                    //shapeclone.data.scale(scale, new LTPoint(p.x, p.y));
                    shapeclone.data.rotate(rotate, new ltpoint_1.LTPoint(0, 0));
                    shapeclone.move(new ltpoint_1.LTPoint(p.x, p.y));
                    var fea = new ltfeature_1.LTFeature({
                        geometry: shapeclone,
                        layer: map.getLayerByName(shape.getLayerName())
                    });
                    map.addFeature(fea);
                });
            }
            map.removeFeatures(feature);
        }
        else if (data instanceof ltentity_1.LTPolyline) {
            let p = data.subData().getExploded();
            p.forEach(shape => {
                let geo = null;
                if (shape instanceof segment_1.RLine) {
                    geo = new ltentity_1.LTLine(shape.getStartPoint().clone(), shape.getEndPoint().clone());
                }
                else if (shape instanceof segment_1.RArc) {
                    geo = new ltentity_1.LTArc(shape.clone());
                }
                if (geo === null) {
                    return;
                }
                var fea = new ltfeature_1.LTFeature({
                    geometry: geo,
                    layer: map.getLayerByName(data.getLayerName())
                });
                map.addFeature(fea);
            });
            map.removeFeatures(feature);
        }
    });
    map.undoStack().endMacro();
    map.printMessage("完成块打散");
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
