"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extendCommand = extendCommand;
const commandfactory_1 = require("../map/commandfactory");
const ltutils_js_1 = require("../cad/ltutils.js");
const segment_1 = require("../cad/segment");
const ltmath_1 = require("../cad/ltmath");
const drawutils_1 = require("./drawutils");
class ExtendCommandImpl {
    ;
    constructor(cadmap) {
        this._canvas = null;
        this.lineColor = 0;
        this._limit_feature = null;
        this._limit_pos = null;
        this._trimed_feature = null;
        this._map = cadmap;
        this._canvas = cadmap.canvas;
        this.lineColor = 0xffd900;
    }
    destroy() {
        this._trimed_feature = null;
        this._limit_feature = null;
    }
    setLimitPos(pos) {
        this._limit_pos = pos;
    }
    save(limit_feature, trim_feature, limit_pos, trimed_pos) {
        if (this._map == null) {
            return;
        }
        var trim_shapes = ExtendCommandImpl.trim(trim_feature.getGeometry(), trimed_pos, limit_feature.getGeometry(), limit_pos);
        if (trim_shapes.length != 1) {
            return;
        }
        this._map.undoStack().beginMacro("extend");
        for (let i = 0; i < trim_shapes.length; ++i) {
            var clone_entity = trim_feature.getGeometry().clone();
            //修改原始对象;
            trim_feature.getGeometry().data = trim_shapes[i];
            this._map.updateFeatureGeometry(trim_feature, clone_entity);
        }
        this._map.undoStack().endMacro();
    }
    static trim(trimShape, trimClickPos, limitingShape, limitingClickPos) {
        var trim_data = trimShape.data;
        var limiting_data = limitingShape.data;
        var shapes = ExtendCommandImpl.extendShapes(trim_data, trimClickPos, limiting_data, limitingClickPos);
        return shapes;
    }
    static extendShapes(trimShape, trimClickPos, limitingShape, limitingClickPos, trimBoth = false) {
        var i1 = 0;
        var segment = null;
        let is_extend = true;
        var trimShapeSimple = trimShape.clone();
        if (trimShape instanceof segment_1.RPolyline) {
            var polyline = trimShape;
            i1 = polyline.getClosestSegment(trimClickPos);
            if (i1 < 0) {
                return [];
            }
            segment = polyline.getSegmentAt(i1);
            if (segment == null) {
                return [];
            }
            trimShapeSimple = segment;
        }
        var i2 = 0;
        var limitingShapeSimple = limitingShape.clone();
        if (limitingShape instanceof segment_1.RPolyline) {
            var polyline = limitingShape;
            i2 = polyline.getClosestSegment(limitingClickPos);
            if (i2 < 0) {
                return [];
            }
            segment = polyline.getSegmentAt(i2);
            if (segment == null) {
                return [];
            }
            limitingShapeSimple = segment;
        }
        // 获取相交点，多端线也是获取一个segment来进行;
        var sol = trimShapeSimple.getIntersectionPoints(limitingShapeSimple, !is_extend);
        if (sol.length == 0) {
            return [];
        }
        let trimmedTrimShape = null;
        let trimmedLimitingShape = null;
        let c;
        let r, am, a1, a2;
        let mp;
        if (trimShape instanceof segment_1.RCircle) {
            let circle = trimShape;
            // convert circle to trimmable arc:
            c = circle.getCenter();
            r = circle.getRadius();
            am = c.getAngleTo(trimClickPos);
            a1 = ltmath_1.LTMath.getNormalizedAngle(am - Math.PI / 2);
            a2 = ltmath_1.LTMath.getNormalizedAngle(am + Math.PI / 2);
            trimmedTrimShape = new segment_1.RArc(c, r, a1, a2, false);
        }
        else if (segment_1.Shape.isFullEllipseShape(trimShape)) {
            if (trimShape instanceof segment_1.REllipse) {
                c = trimShape.getCenter();
                mp = trimShape.getMajorPoint();
                r = trimShape.getRatio();
                am = trimShape.getParamTo(trimClickPos);
                a1 = ltmath_1.LTMath.getNormalizedAngle(am - Math.PI / 2);
                a2 = ltmath_1.LTMath.getNormalizedAngle(am + Math.PI / 2);
                trimmedTrimShape = new segment_1.REllipse(c, mp, r, a1, a2, false);
            }
        }
        else {
            trimmedTrimShape = trimShape.clone();
        }
        if (trimBoth) {
            if (limitingShape instanceof segment_1.RCircle) {
                let circle = limitingShape;
                c = circle.getCenter();
                r = circle.getRadius();
                am = c.getAngleTo(trimClickPos);
                a1 = ltmath_1.LTMath.getNormalizedAngle(am - Math.PI / 2);
                a2 = ltmath_1.LTMath.getNormalizedAngle(am + Math.PI / 2);
                trimmedLimitingShape = new segment_1.RArc(c, r, a1, a2, false);
            }
            else {
                trimmedLimitingShape = limitingShape.clone();
            }
        }
        var isIdx = -1;
        if (trimBoth || trimShape.isEllipseShape()) {
            isIdx = trimClickPos.getClosestIndex(sol);
        }
        else {
            isIdx = limitingClickPos.getClosestIndex(sol);
        }
        if (isIdx == -1) {
            return [];
        }
        var is = sol[isIdx];
        var is2;
        if (sol.length == 1 || isIdx != 0) {
            is2 = sol[0];
        }
        else {
            is2 = sol[1];
        }
        // trim trim entity:
        if (trimmedTrimShape !== null) {
            var ending1 = trimmedTrimShape.getTrimEnd(is, trimClickPos);
            if (ending1 == false) {
                trimmedTrimShape.trimStartPoint(is, trimClickPos, is_extend);
                if (trimShape instanceof segment_1.RCircle) {
                    trimmedTrimShape.trimEndPoint(is2, trimClickPos, is_extend);
                }
                else if (trimShape instanceof segment_1.RPolyline) {
                    trimShape.insertVertex(0, trimmedTrimShape.getStartPoint());
                }
            }
            else {
                trimmedTrimShape.trimEndPoint(is, trimClickPos, is_extend);
                if (trimShape instanceof segment_1.RCircle) {
                    trimmedTrimShape.trimStartPoint(is2, trimClickPos, is_extend);
                }
                else if (trimShape instanceof segment_1.RPolyline) {
                    trimShape.vertices[trimShape.countVertices() - 1] = trimmedTrimShape.getEndPoint();
                }
            }
        }
        var ending2 = false;
        if (trimBoth && trimmedLimitingShape) {
            ending2 = trimmedLimitingShape.getTrimEnd(is, limitingClickPos);
            if (ending2 == false) {
                trimmedLimitingShape.trimStartPoint(is, limitingClickPos, is_extend);
                if (limitingShape instanceof segment_1.RCircle) {
                    trimmedLimitingShape.trimEndPoint(is2, limitingClickPos, is_extend);
                }
            }
            else {
                trimmedLimitingShape.trimEndPoint(is, limitingClickPos, is_extend);
                if (limitingShape instanceof segment_1.RCircle) {
                    trimmedLimitingShape.trimStartPoint(is2, limitingClickPos, is_extend);
                }
            }
        }
        var ret = [];
        if (trimmedTrimShape !== null) {
            ret.push(trimmedTrimShape);
        }
        if (trimmedLimitingShape !== null) {
            ret.push(trimmedLimitingShape);
        }
        return ret;
    }
}
function* extendCommand(map, param_map) {
    map.cursorType = ltutils_js_1.MapCursorType.CursorSingleSelect;
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定基对象:");
    let select_pos = yield;
    if (select_pos.done == commandfactory_1.CommandStatus.eCancel || select_pos.done == commandfactory_1.CommandStatus.eDone) {
        return select_pos;
    }
    let map_pos = select_pos.value;
    let limit_feature = map.selectSingleFeature(map_pos);
    if (limit_feature == null) {
        return { value: null, status: commandfactory_1.CommandStatus.eCancel };
    }
    let extend_command = new ExtendCommandImpl(map);
    drawutils_1.DrawUtilImpl.highlightEntity(map, limit_feature.getGeometry());
    function selectCallback(event) {
        //实时高亮不做；
    }
    while (true) {
        commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定需要延伸的对象:", selectCallback);
        let extend_pos = yield;
        if (extend_pos.done == commandfactory_1.CommandStatus.eCancel || extend_pos.done == commandfactory_1.CommandStatus.eDone) {
            extend_command.destroy();
            drawutils_1.DrawUtilImpl.cancelhighlightEntity(map, limit_feature.getGeometry());
            return extend_pos;
        }
        let extend_feature = map.selectSingleFeature(extend_pos.value);
        if (extend_feature == null) {
            continue;
        }
        extend_command.save(limit_feature, extend_feature, map_pos, extend_pos.value);
    }
}
