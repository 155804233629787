"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exportPDF = exportPDF;
exports.clearSpireDraw = clearSpireDraw;
const ltentity_1 = require("../../cadplugins/cad/ltentity");
const commandfactory_1 = require("../../cadplugins/map/commandfactory");
const selectcommands_1 = require("../../cadplugins/tools/selectcommands");
const sprinklerutils_1 = require("../sprinkler/sprinklerutils");
function* exportPDF(map, param_map) {
    let style_name = param_map.style_name;
    if (style_name === undefined || style_name === null) {
        map.printMessage("请指定喷淋图形的样式名称");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    //选择对象;
    function selectCallback(fea) {
        let sub_geo = fea.getGeometry();
        if (!(sub_geo instanceof ltentity_1.LTPolyline)) {
            return false;
        }
        if (sub_geo.subData().vertices.length != 5) {
            return false;
        }
        if (fea.layer.name === sprinklerutils_1.FLOOR_LAYER) {
            return true;
        }
        return false;
    }
    let frames = param_map.frames;
    if (frames.length == 0) {
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    let json_list = [];
    for (let i = 0; i < frames.length; i++) {
        let geometry = frames[i].feature.getGeometry();
        let pdf_name = frames[i].name;
        let floor_val = {};
        if (geometry instanceof ltentity_1.LTEntityPoint) {
            let entity_point = geometry;
            let scale_point = entity_point.subData().getScale();
            let box = entity_point.getBoundingBox();
            floor_val = {
                w: box.getWidth() / scale_point.x,
                h: box.getHeight() / scale_point.y,
                minX: box.getMinimum().x,
                minY: box.getMinimum().y,
                maxX: box.getMaximum().x,
                maxY: box.getMaximum().y,
                name: pdf_name,
            };
        }
        else if (geometry instanceof ltentity_1.LTPolyline) {
            if (!geometry.subData().isClosed()) {
                continue;
            }
            let box = geometry.getBoundingBox();
            floor_val = {
                w: param_map.page.width * 1.0,
                h: param_map.page.height * 1.0,
                minX: box.getMinimum().x,
                minY: box.getMinimum().y,
                maxX: box.getMaximum().x,
                maxY: box.getMaximum().y,
                name: pdf_name,
            };
        }
        if (param_map.page !== undefined) {
            //重新修改这个大小;
            floor_val.w = param_map.page.width * 1.0;
            floor_val.h = param_map.page.height * 1.0;
        }
        json_list.push(floor_val);
    }
    if (json_list.length == 0) {
        map.printMessage("未正确获取到范围");
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    let is_zip = param_map.zip;
    if (param_map.margin === undefined) {
        let pdf_json = {
            extinfo: json_list,
            plotstyle: style_name,
        };
        window.downLoadPdf(JSON.stringify(pdf_json), is_zip);
    }
    else {
        let pdf_json = {
            extinfo: json_list,
            plotstyle: style_name,
            margins: {
                top: param_map.margin.top * 1.0,
                bottom: param_map.margin.bottom * 1.0,
                right: param_map.margin.right * 1.0,
                left: param_map.margin.left * 1.0
            }
        };
        window.downLoadPdf(JSON.stringify(pdf_json), is_zip);
    }
}
//清除自动生成的喷淋图形;
function* clearSpireDraw(map, param_map) {
    function selectCallback(fea) {
        if (fea._xdata == null) {
            return false;
        }
        let source = fea._xdata.source;
        if (source === undefined || source === null) {
            return false;
        }
        if (source.toLowerCase() === "autogenerate") {
            return true;
        }
        return false;
    }
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, { command_tip: "请选择要清除的喷淋图形:", callback: selectCallback, select_type: selectcommands_1.SelectType.eSelectBox });
    //清空喷淋绘制的图形;
    let inpt_select = yield;
    if (inpt_select.done === commandfactory_1.CommandStatus.eCancel || inpt_select.done === commandfactory_1.CommandStatus.eDone) {
        return inpt_select;
    }
    commandfactory_1.CommandFactory.getInstance().getString(map, "是否删除选择的数据[是(Y) 否(N)]<Y>:");
    let clear_command = yield;
    if (clear_command.done === commandfactory_1.CommandStatus.eCancel || clear_command.done === commandfactory_1.CommandStatus.eDone) {
        return clear_command;
    }
    if (clear_command.done !== commandfactory_1.CommandStatus.eKeyWord) {
        return clear_command;
    }
    let clear_command_value = clear_command.value;
    if (clear_command_value === "N") {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    map.clearSelection();
    map.removeFeatures(inpt_select.value);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
