"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addhatch = addhatch;
const ltentity_1 = require("../cad/ltentity");
const segment_1 = require("../cad/segment");
const ltfeature_1 = require("../data/ltfeature");
const commandfactory_1 = require("../map/commandfactory");
const selectcommands_1 = require("./selectcommands");
function* addhatch(map, command_param) {
    function closeCallback(fea) {
        let data = fea.getGeometry().data;
        if (data instanceof segment_1.RPolyline) {
            return data.isClosed();
        }
        if (data instanceof segment_1.RCircle) {
            return true;
        }
        return false;
    }
    let param = { command_tip: "Hatch:", select_type: selectcommands_1.SelectType.eSelectPointAndBox, callback: closeCallback };
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, param);
    let select_feature_command = yield;
    if (select_feature_command.done === commandfactory_1.CommandStatus.eCancel || select_feature_command.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    if (map.selectedFeatures().length == 0) {
        map.printMessage("未选择对象，请选择要添加的图形对象");
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    var hatch = new ltentity_1.LTHatch();
    map.selectedFeatures().forEach(feature => {
        let geo = feature.getGeometry().data;
        if (geo instanceof segment_1.RPolyline) {
            hatch.subData().addloops(geo);
        }
        if (geo instanceof segment_1.RCircle) {
            hatch.subData().addloops(geo);
        }
    });
    hatch.hatchName = "Soild";
    var fea = new ltfeature_1.LTFeature({
        geometry: hatch,
        layer: map.activeLayer()
    });
    map.addFeature(fea);
    map.printMessage("完成hatch");
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
