"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.undoCommand = undoCommand;
exports.undoCommand1 = undoCommand1;
exports.redoCommand = redoCommand;
exports.redoCommand1 = redoCommand1;
const commandfactory_1 = require("../map/commandfactory");
function* undoCommand(cadmap, param_map) {
    commandfactory_1.CommandFactory.getInstance().getNumber(cadmap, "指定撤销步数<1>:");
    let input_count = yield;
    if (input_count.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let count = input_count.value || 1;
    let undo_count = 0;
    let is_break = false;
    while (count > 0) {
        if (cadmap.undoStack().canUndo()) {
            cadmap.undoStack().undo();
            undo_count += 1;
        }
        else {
            is_break = true;
            break;
        }
        count--;
    }
    if (is_break) {
        cadmap.printMessage("已经全部撤销");
    }
    else {
        cadmap.printMessage(`已撤销${undo_count}步`);
    }
    cadmap.invalidate();
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
function* undoCommand1(cadmap, param_map) {
    if (cadmap.undoStack().canUndo()) {
        cadmap.undoStack().undo();
        cadmap.invalidate();
    }
    else {
        cadmap.printMessage("已经全部撤销");
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
function* redoCommand(cadmap, param_map) {
    commandfactory_1.CommandFactory.getInstance().getNumber(cadmap, "指定重做步数<1>:");
    let input_count = yield;
    if (input_count.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let count = input_count.value || 1;
    let redo_count = 0;
    let is_break = false;
    while (count > 0) {
        if (cadmap.undoStack().canRedo()) {
            cadmap.undoStack().redo();
            redo_count += 1;
        }
        else {
            is_break = true;
            break;
        }
        count--;
    }
    if (is_break) {
        cadmap.printMessage("已经全部重做");
    }
    else {
        cadmap.printMessage(`已撤销${redo_count}步`);
    }
    cadmap.invalidate();
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
function* redoCommand1(cadmap, param_map) {
    if (cadmap.undoStack().canRedo()) {
        cadmap.undoStack().redo();
        cadmap.invalidate();
    }
    else {
        cadmap.printMessage("已经全部重做");
    }
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
