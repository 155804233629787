"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawWarehouse = drawWarehouse;
const ltentity_1 = require("../../cadplugins/cad/ltentity");
const ltpoint_1 = require("../../cadplugins/cad/ltpoint");
const ltfeature_1 = require("../../cadplugins/data/ltfeature");
const ltfields_1 = require("../../cadplugins/data/ltfields");
const commandfactory_1 = require("../../cadplugins/map/commandfactory");
const selectcommands_1 = require("../../cadplugins/tools/selectcommands");
const shelveMainProcess_1 = require("../shelve/shelveMainProcess");
function* drawWarehouse(map, param_map) {
    //货架单元
    let selectfeatures = [];
    //奇数
    let oddNumberFeatures = [];
    //偶数
    let evenNumberFeatures = [];
    //主管
    let mainPipeFeatures = [];
    //选择要素时绘制的矩形或多边形
    let drawPolygonPoints = [];
    //设置出图比例
    let drawscale = 1;
    while (true) {
        {
            commandfactory_1.CommandFactory.getInstance().getString(map, "出图比例 1:[100(1)]<1>:");
            let scale_type = yield;
            if (scale_type.done == commandfactory_1.CommandStatus.eDone) {
            }
            if (scale_type.done == commandfactory_1.CommandStatus.eCancel) {
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            if (scale_type.value.toLowerCase() == "1") {
                drawscale = 1;
            }
            else {
                drawscale = Number(scale_type.value) / 100;
            }
        }
        map.select_command.clearSelection();
        {
            let back = false;
            while (true) {
                commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, { command_tip: "框选货架单元", select_type: selectcommands_1.SelectType.eSelectBox, back: true });
                let select_type = yield;
                if (select_type.done == commandfactory_1.CommandStatus.eDone) {
                    break;
                }
                if (select_type.done == commandfactory_1.CommandStatus.eCancel) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                if (select_type.done == commandfactory_1.CommandStatus.eKeyWord && select_type.value == 2) {
                    back = true;
                    break;
                }
                selectfeatures = select_type.value;
                drawPolygonPoints = select_type.points;
                if (selectfeatures === null || selectfeatures.length == 0) {
                    continue;
                }
                break;
            }
            //和喷淋绘制一样，是框选或绘制多边形选择的操作，把选中的要素存在数组里，然后预留一个空方法
            //debugger
            map.select_command.addSelectFeatures(selectfeatures);
            map.select_command.show();
            if (back == true) {
                continue;
            }
        }
        //step1:识别货架单元
        let shelveMainProcess = new shelveMainProcess_1.ShelveMainProcess(map, param_map.sprinklerBlockName, param_map.pipeSizeMap, drawscale);
        shelveMainProcess.extractShelveUnit(selectfeatures, drawPolygonPoints);
        let last_postion = ltpoint_1.LTPoint.nullVector();
        let drawWarehouse = false;
        map.undoStack().beginMacro("绘制货架");
        while (true) {
            if (drawWarehouse) {
                commandfactory_1.CommandFactory.getInstance().getString(map, "喷头布置[布置奇数层(1) 布置偶数层(2) 绘制喷淋主干管(3) 返回上一步(4)]<3>:");
            }
            else {
                commandfactory_1.CommandFactory.getInstance().getString(map, "喷头布置[布置奇数层(1) 布置偶数层(2) 返回上一步(4)]<2>:");
            }
            let select_mode = yield;
            if (select_mode.done == commandfactory_1.CommandStatus.eCancel) {
                map.undoStack().endMacro();
                return { value: null, done: commandfactory_1.CommandStatus.eCancel };
            }
            if (select_mode.value.toLowerCase() == "1") {
                map.select_command.clearSelection();
                let param = { command_tip: "请选择奇数层:", select_type: selectcommands_1.SelectType.eSelectBox };
                commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, param);
                let mouse1 = yield;
                if (mouse1.done == commandfactory_1.CommandStatus.eDone) {
                    break;
                }
                if (mouse1.done == commandfactory_1.CommandStatus.eCancel) {
                    map.undoStack().endMacro();
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                oddNumberFeatures = mouse1.value;
                drawWarehouse = true;
                shelveMainProcess.generateSprinkler(oddNumberFeatures, true);
            }
            else if (select_mode.value.toLowerCase() == "2") {
                map.select_command.clearSelection();
                let param = { command_tip: "请选择偶数层:", select_type: selectcommands_1.SelectType.eSelectBox };
                commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, param);
                let mouse1 = yield;
                if (mouse1.done == commandfactory_1.CommandStatus.eDone) {
                    break;
                }
                if (mouse1.done == commandfactory_1.CommandStatus.eCancel) {
                    map.undoStack().endMacro();
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                evenNumberFeatures = mouse1.value;
                drawWarehouse = true;
                shelveMainProcess.generateSprinkler(evenNumberFeatures, false);
            }
            else if (select_mode.value.toLowerCase() == "3") {
                //框选要素
                map.select_command.clearSelection();
                let param1 = { command_tip: "请选择货架要素:", select_type: selectcommands_1.SelectType.eSelectBox };
                commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", map, param1);
                let mouse1 = yield;
                if (mouse1.done == commandfactory_1.CommandStatus.eDone) {
                    break;
                }
                if (mouse1.done == commandfactory_1.CommandStatus.eCancel) {
                    map.undoStack().endMacro();
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                mainPipeFeatures = mouse1.value;
                let param2 = { tip: "绘制喷淋主干管 ", noSaveFeature: true };
                commandfactory_1.CommandFactory.getInstance().execYieldCommand("pline", map, param2);
                let result = yield;
                if (result.done == commandfactory_1.CommandStatus.eCancel) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                let atlayer = map.activeLayer();
                if (atlayer === null || result.value === null) {
                    return { value: null, done: commandfactory_1.CommandStatus.eCancel };
                }
                let mainFeature = new ltfeature_1.LTFeature({
                    geometry: result.value.clone(),
                    layer: atlayer
                });
                mainFeature.setValue(ltfields_1.FieldNames.EntityLayer, atlayer.name);
                //mainFeature.setValue(FieldNames.EntityType, "AcDbBlockReference");
                mainFeature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
                mainFeature.setValue(ltfields_1.FieldNames.Width, 0.0);
                mainFeature.setValue(ltfields_1.FieldNames.Rotate, 0.0);
                //map.addFeature(mainFeature);
                //mainPipeFeatures.push(mainFeature);
                shelveMainProcess.generateMainPipe(mainPipeFeatures, mainFeature.getGeometry().subData().getEndPoints());
                return { value: {}, done: commandfactory_1.CommandStatus.eDone };
            }
            else if (select_mode.value.toLowerCase() == "4") {
                break;
            }
            map.undoStack().endMacro();
        }
    }
    return { value: {}, done: commandfactory_1.CommandStatus.eDone };
}
