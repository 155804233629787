"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrimCommandImpl = void 0;
exports.trimCommand = trimCommand;
const commandfactory_1 = require("../map/commandfactory");
const ltutils_js_1 = require("../cad/ltutils.js");
const segment_1 = require("../cad/segment");
const ltmath_1 = require("../cad/ltmath");
const drawutils_1 = require("./drawutils");
class TrimCommandImpl {
    static trim(trimShape, trimClickPos, limitingShape, limitingClickPos) {
        var trim_data = trimShape.data;
        var limiting_data = limitingShape.data;
        var shapes = TrimCommandImpl.trimShapes(trim_data, trimClickPos, limiting_data, limitingClickPos);
        return shapes;
    }
    static save(map, limit_feature, trim_feature, limit_pos, trimed_pos) {
        var trim_shapes = TrimCommandImpl.trim(trim_feature.getGeometry(), trimed_pos, limit_feature.getGeometry(), limit_pos);
        if (trim_shapes.length == 0) {
            return;
        }
        map.undoStack().beginMacro("trim");
        map.removeFeatures(trim_feature);
        for (let i = 0; i < trim_shapes.length; ++i) {
            let new_feature = trim_feature.clone();
            let new_ent = ltutils_js_1.LTUtils.shapeToEntity(trim_shapes[i]);
            if (new_ent == null) {
                continue;
            }
            //对于圆来说，图形类型已经改变，就不能用原来的引用了。那么要做的是先删除，再添加;
            new_ent.cloneFrom(new_feature.getGeometry());
            new_ent.data = trim_shapes[i];
            new_feature.setGeometry(new_ent);
            map.addFeature(new_feature);
        }
        map.undoStack().endMacro();
    }
    static trimShapes(trimShape, trimClickPos, limitingShape, limitingClickPos, trimBoth = false) {
        var i1 = 0;
        var segment = null;
        let temp_trim_shape = trimShape.clone();
        // 获取相交点，多端线也是获取一个segment来进行;
        var sol = temp_trim_shape.getIntersectionPoints(limitingShape, true);
        if (sol.length == 0) {
            return [];
        }
        let trimmedTrimShape = null;
        let trimmedLimitingShape = null;
        let c;
        let r, am, a1, a2;
        let mp;
        if (temp_trim_shape instanceof segment_1.RCircle) {
            // convert circle to trimmable arc:
            c = temp_trim_shape.getCenter();
            r = temp_trim_shape.getRadius();
            am = c.getAngleTo(trimClickPos);
            a1 = ltmath_1.LTMath.getNormalizedAngle(am - Math.PI / 2);
            a2 = ltmath_1.LTMath.getNormalizedAngle(am + Math.PI / 2);
            trimmedTrimShape = new segment_1.RArc(c, r, a1, a2, false);
        }
        else if (segment_1.Shape.isFullEllipseShape(trimShape)) {
            if (trimShape instanceof segment_1.REllipse) {
                c = trimShape.getCenter();
                mp = trimShape.getMajorPoint();
                r = trimShape.getRatio();
                am = trimShape.getParamTo(trimClickPos);
                a1 = ltmath_1.LTMath.getNormalizedAngle(am - Math.PI / 2);
                a2 = ltmath_1.LTMath.getNormalizedAngle(am + Math.PI / 2);
                trimmedTrimShape = new segment_1.REllipse(c, mp, r, a1, a2, false);
            }
        }
        else if (temp_trim_shape instanceof segment_1.RPolyline) {
            //获取距离相交点最近的一个点。
            let closet_point = temp_trim_shape.getClosestPointOnShape(trimClickPos);
            if (closet_point == null) {
                return [];
            }
            let distance = temp_trim_shape.getLengthTo(closet_point);
            let total_length = 0;
            var ret = [];
            for (let i = 0; i < sol.length; ++i) {
                let sub_length = temp_trim_shape.getLengthTo(sol[i]);
                if (sub_length <= distance) {
                    continue;
                }
                if (i == 0) {
                    temp_trim_shape.trimStartPoint(sol[i], trimClickPos, false);
                    var ret = [];
                    if (temp_trim_shape.getLength() < 1e-4) {
                        return [];
                    }
                    ret.push(temp_trim_shape);
                    return ret;
                }
                else {
                    temp_trim_shape.trimEndPoint(sol[i - 1], trimClickPos, false);
                    if (temp_trim_shape.getLength() > 1e-4) {
                        ret.push(temp_trim_shape);
                    }
                    let sub_shape = trimShape.clone();
                    sub_shape.trimStartPoint(sol[i], trimClickPos, false);
                    if (sub_shape.getLength() > 1e-4) {
                        ret.push(sub_shape);
                    }
                    return ret;
                }
            }
            //裁剪末尾;
            if (sol.length > 0) {
                temp_trim_shape.trimEndPoint(sol[sol.length - 1], trimClickPos, false);
                if (temp_trim_shape.getLength() < 1e-4) {
                    return [];
                }
                ret.push(temp_trim_shape);
                return ret;
            }
        }
        else {
            trimmedTrimShape = trimShape.clone();
        }
        if (trimBoth) {
            if (limitingShape instanceof segment_1.RCircle) {
                let circle = limitingShape;
                c = circle.getCenter();
                r = circle.getRadius();
                am = c.getAngleTo(trimClickPos);
                a1 = ltmath_1.LTMath.getNormalizedAngle(am - Math.PI / 2);
                a2 = ltmath_1.LTMath.getNormalizedAngle(am + Math.PI / 2);
                trimmedLimitingShape = new segment_1.RArc(c, r, a1, a2, false);
            }
            else {
                trimmedLimitingShape = limitingShape.clone();
            }
        }
        var isIdx = -1;
        if (trimBoth || trimShape.isEllipseShape()) {
            isIdx = trimClickPos.getClosestIndex(sol);
        }
        else {
            isIdx = limitingClickPos.getClosestIndex(sol);
        }
        if (isIdx == -1) {
            return [];
        }
        var is = sol[isIdx];
        var is2;
        if (sol.length == 1 || isIdx != 0) {
            is2 = sol[0];
        }
        else {
            is2 = sol[1];
        }
        if (trimmedTrimShape !== null) {
            var ending1 = trimmedTrimShape.getTrimEnd(is, trimClickPos);
            if (ending1) {
                trimmedTrimShape.trimStartPoint(is, trimClickPos);
                if (trimShape instanceof segment_1.RCircle) {
                    trimmedTrimShape.trimEndPoint(is2, trimClickPos);
                }
            }
            else {
                trimmedTrimShape.trimEndPoint(is, trimClickPos);
                if (trimShape instanceof segment_1.RCircle) {
                    trimmedTrimShape.trimStartPoint(is2, trimClickPos);
                }
            }
        }
        var ending2 = false;
        if (trimBoth && trimmedLimitingShape) {
            ending2 = trimmedLimitingShape.getTrimEnd(is, limitingClickPos);
            if (ending2) {
                trimmedLimitingShape.trimStartPoint(is, limitingClickPos);
                if (limitingShape instanceof segment_1.RCircle) {
                    trimmedLimitingShape.trimEndPoint(is2, limitingClickPos);
                }
            }
            else {
                trimmedLimitingShape.trimEndPoint(is, limitingClickPos);
                if (limitingShape instanceof segment_1.RCircle) {
                    trimmedLimitingShape.trimStartPoint(is2, limitingClickPos);
                }
            }
        }
        var ret = [];
        if (trimmedTrimShape !== null) {
            ret.push(trimmedTrimShape);
        }
        if (trimmedLimitingShape !== null) {
            ret.push(trimmedLimitingShape);
        }
        return ret;
    }
}
exports.TrimCommandImpl = TrimCommandImpl;
function* trimCommand(map, param_map) {
    map.cursorType = ltutils_js_1.MapCursorType.CursorSingleSelect;
    commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定基对象:");
    let select_pos = yield;
    if (select_pos.done == commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let map_pos = select_pos.value;
    let limit_feature = map.selectSingleFeature(map_pos);
    if (limit_feature == null) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    drawutils_1.DrawUtilImpl.highlightEntity(map, limit_feature.getGeometry());
    function selectCallback(event) {
    }
    while (true) {
        commandfactory_1.CommandFactory.getInstance().getPoint(map, "指定需要裁剪的对象:", selectCallback);
        let extend_pos = yield;
        if (extend_pos.done == commandfactory_1.CommandStatus.eCancel || extend_pos.done == commandfactory_1.CommandStatus.eDone) {
            drawutils_1.DrawUtilImpl.cancelhighlightEntity(map, limit_feature.getGeometry());
            return extend_pos;
        }
        let extend_feature = map.selectSingleFeature(extend_pos.value);
        if (extend_feature == null) {
            continue;
        }
        drawutils_1.DrawUtilImpl.cancelhighlightEntity(map, limit_feature.getGeometry());
        TrimCommandImpl.save(map, limit_feature, extend_feature, map_pos, extend_pos.value);
    }
}
