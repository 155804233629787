"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveCommand = moveCommand;
exports.offsetCommand = offsetCommand;
exports.copyCommand = copyCommand;
const commandfactory_1 = require("../map/commandfactory");
const ltpoint_1 = require("../cad/ltpoint");
const ltfeature_1 = require("../data/ltfeature");
const ltentity_1 = require("../cad/ltentity");
const ltutils_1 = require("../cad/ltutils");
const segment_1 = require("../cad/segment");
const drawutils_1 = require("./drawutils");
class MoveCommand {
    constructor(cadmap, is_copy = false) {
        this.lineColor = 0;
        this.selectFeatures = [];
        this._is_copy = false;
        this._map = cadmap;
        this.lineColor = 0xffd900;
        this._is_copy = is_copy;
    }
    draws(entitys) {
        this._map.trackGraphics.clear();
        entitys.forEach(entity => {
            entity.worldDraw(this._map.canvas, true, null, this._map.trackGraphics);
        });
        return commandfactory_1.CommandStatus.eOK;
    }
    clear() {
        this._map.trackGraphics.clear();
    }
    setFirstPos(map_pos) {
        this._pos = new ltpoint_1.LTPoint(map_pos.x, map_pos.y);
    }
    dragMove(map_pos) {
        let offset = new ltpoint_1.LTPoint(map_pos.x - this._pos.x, map_pos.y - this._pos.y);
        let geometrys = [];
        this.selectFeatures.forEach(feature => {
            let geo = feature.getGeometry().clone();
            geo.move(offset);
            geometrys.push(geo);
        });
        this.draws(geometrys);
    }
    save(target_pos) {
        let offset = new ltpoint_1.LTPoint(target_pos.x - this._pos.x, target_pos.y - this._pos.y);
        let macro_str = "move";
        if (this._is_copy) {
            macro_str = "copy";
            this._map.undoStack().beginMacro(macro_str);
            this.selectFeatures.forEach(feature => {
                var clone_feature = feature.clone();
                clone_feature.getGeometry().move(offset);
                this._map.addFeature(clone_feature);
            });
            this._map.undoStack().endMacro();
        }
        else {
            this._map.undoStack().beginMacro(macro_str);
            this.selectFeatures.forEach(feature => {
                var clone_ent = feature.getGeometry().clone();
                feature.getGeometry().move(offset);
                this._map.updateFeatureGeometry(feature, clone_ent);
            });
            this._map.undoStack().endMacro();
        }
        this._map.select_command.show();
    }
}
function* moveCommand(cadmap, param_map) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", cadmap);
    let select_feature = yield;
    if (select_feature.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定基点:");
    let base_point = yield;
    if (base_point.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let command = new MoveCommand(cadmap);
    command.setFirstPos(base_point.value);
    command.selectFeatures = select_feature.value;
    let track_line = new ltentity_1.LTLine(base_point.value, base_point.value);
    let snap_feature = new ltfeature_1.LTFeature({ geometry: new ltentity_1.LTEntityPoint(base_point.value) });
    cadmap.snap.setFeature(snap_feature);
    function moveCallback(mouse_event) {
        command.dragMove(mouse_event.mapPos);
        track_line.setEnd(mouse_event.mapPos);
        drawutils_1.DrawUtilImpl.drawDashLine(cadmap, track_line);
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定第二个点:", moveCallback);
    let target_point = yield;
    if (target_point.done === commandfactory_1.CommandStatus.eCancel || target_point.done === commandfactory_1.CommandStatus.eDone) {
        command.clear();
        drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, track_line);
        return { value: null, done: target_point.done };
    }
    command.save(target_point.value);
    command.clear();
    drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, track_line);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
//偏移命令;
function* offsetCommand(cadmap, param_map) {
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", cadmap, { command_tip: "选择要偏移的对象" });
    let select_feature = yield;
    if (select_feature.done === commandfactory_1.CommandStatus.eCancel || select_feature.value.length === 0) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let feature = select_feature.value[0];
    let ents = [];
    function trackOffset(mouse) {
        let distance = feature.getGeometry().data.getDistanceTo(mouse.mapPos);
        let shapes = segment_1.Shape.getOffsetArcs(feature.getGeometry().data, distance, 1, segment_1.Side.LeftHand, mouse.mapPos);
        let new_ents = [];
        for (let i = 0; i < shapes.length; i++) {
            let sub_ent = ltutils_1.LTUtils.shapeToEntity(shapes[i]);
            if (sub_ent != null) {
                new_ents.push(sub_ent);
            }
        }
        for (let i = 0; i < ents.length; i++) {
            drawutils_1.DrawUtilImpl.destroyEntity(cadmap, ents[i]);
        }
        for (let i = 0; i < new_ents.length; i++) {
            drawutils_1.DrawUtilImpl.trackEntity(cadmap, new_ents[i]);
        }
        ents = new_ents;
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定通过点:", trackOffset);
    let base_point = yield;
    if (base_point.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
}
function* copyCommand(cadmap, param_map) {
    //请选择需要拷贝的对象;
    commandfactory_1.CommandFactory.getInstance().execYieldCommand("select", cadmap, { command_tip: "请选择需要拷贝的对象" });
    let select_feature = yield;
    if (select_feature.done === commandfactory_1.CommandStatus.eCancel || select_feature.value.length === 0 || select_feature.done === commandfactory_1.CommandStatus.eDone) {
        return { value: null, done: commandfactory_1.CommandStatus.eDone };
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定基点:");
    let base_point = yield;
    if (base_point.done === commandfactory_1.CommandStatus.eCancel) {
        return { value: null, done: commandfactory_1.CommandStatus.eCancel };
    }
    let command = new MoveCommand(cadmap, true);
    command.setFirstPos(base_point.value);
    command.selectFeatures = select_feature.value;
    let track_line = new ltentity_1.LTLine(base_point.value, base_point.value);
    let snap_feature = new ltfeature_1.LTFeature({ geometry: new ltentity_1.LTEntityPoint(base_point.value) });
    cadmap.snap.setFeature(snap_feature);
    function moveCallback(mouse_event) {
        command.dragMove(mouse_event.mapPos);
        track_line.setEnd(mouse_event.mapPos);
        drawutils_1.DrawUtilImpl.drawDashLine(cadmap, track_line);
    }
    commandfactory_1.CommandFactory.getInstance().getPoint(cadmap, "指定第二个点:", moveCallback);
    let target_point = yield;
    if (target_point.done === commandfactory_1.CommandStatus.eCancel || target_point.done === commandfactory_1.CommandStatus.eDone) {
        command.clear();
        drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, track_line);
        return { value: null, done: target_point.done };
    }
    command.clear();
    command.save(target_point.value);
    drawutils_1.DrawUtilImpl.destroyDashEntity(cadmap, track_line);
    return { value: null, done: commandfactory_1.CommandStatus.eDone };
}
