"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawBlockCommand = void 0;
exports.drawBlock = drawBlock;
const commandfactory_1 = require("../map/commandfactory");
const ltentity_1 = require("../cad/ltentity");
const ltfeature_1 = require("../data/ltfeature");
const ltfields_1 = require("../data/ltfields");
class DrawBlockCommand {
    constructor() {
        this._map = null;
        this._blockEntity = null;
        this._feature = null;
        this._rotate = 0;
        this._expressionText = new Map();
        this._isExpression = false;
        this.scale = 1;
    }
    init(cadmap, form) {
        if (form == undefined) {
            return false;
        }
        this._map = cadmap;
        this._feature = null;
        this._from = form;
        this._blockEntity = new ltentity_1.LTEntityPoint();
        this._blockEntity.setColor("0xff00bb");
        this._blockEntity.blockName = form.blockName;
        if (form.blockScale !== undefined) {
            this._blockEntity.subData().setScale(form.blockScale, form.blockScale);
        }
        this._blockEntity.subData().setRotate(this._rotate);
        this.setExpressiongValue(this._from.blockinfo);
        return true;
    }
    setExpressiongValue(expresion) {
        if (expresion == undefined) {
            return;
        }
        expresion.forEach((value, key) => {
            if (this._blockEntity === null) {
                return;
            }
            this._blockEntity.subData()._blockInfo[key] = { text: value };
        });
        //此处加值
    }
    clear() {
        if (this._blockEntity === null || this._map == null) {
            return;
        }
        this._map.removeGraphics(this._blockEntity, this._map.trackGraphics);
        this._blockEntity = null;
    }
    static newIntance() {
        var command = new DrawBlockCommand();
        return command;
    }
    getFeature() {
        return this._feature;
    }
    setPoint(pos) {
        if (this._blockEntity === null || this._map === null) {
            return;
        }
        this._blockEntity.setPoint(pos);
        this.drawblocks();
    }
    drawblocks() {
        if (this._blockEntity === null || this._map === null) {
            return;
        }
        this._map.removeGraphics(this._blockEntity, this._map.trackGraphics);
        this._blockEntity.worldDraw(this._map.canvas, true, null, this._map.trackGraphics);
    }
    onMouseMove(point) {
        this.setPoint(point);
        return commandfactory_1.CommandStatus.eOK;
    }
    getEntiry() {
        return this._blockEntity;
    }
    save() {
        if (this._blockEntity === null || this._map === null) {
            return commandfactory_1.CommandStatus.eDone;
        }
        let layer = this._map.activeLayer();
        if (this._from.layername !== undefined) {
            let atlayer = this._map.getLayerByName(this._from.layername);
            if (atlayer !== null) {
                layer = atlayer;
            }
        }
        this._feature = new ltfeature_1.LTFeature({
            geometry: this._blockEntity.clone(),
            layer: layer
        });
        this._feature.setValue(ltfields_1.FieldNames.EntityLayer, layer.name);
        this._feature.setValue(ltfields_1.FieldNames.Color, ltentity_1.dwg_bylayer);
        this._feature.setValue(ltfields_1.FieldNames.Width, 0.0);
        this._feature.setValue(ltfields_1.FieldNames.Rotate, this._rotate);
        this._map.addFeature(this._feature);
    }
    onMouseDown(point) {
        if (this._blockEntity === null || this._map === null) {
            return commandfactory_1.CommandStatus.eDone;
        }
        this.setPoint(point);
        return commandfactory_1.CommandStatus.eOK;
    }
    clearFeature() {
        this._feature = null;
    }
    setRotate(val) {
        this._rotate = val; //(val * 1 * Math.PI) / 180;
        if (this._blockEntity !== null) {
            this._blockEntity.subData().setRotate(val); //this._rotate);
            this.drawblocks();
        }
    }
}
exports.DrawBlockCommand = DrawBlockCommand;
function* drawBlock(cad, command_param) {
    let command = DrawBlockCommand.newIntance();
    let features = [];
    if (command.init(cad, command_param)) {
        while (true) {
            function drawCallback(mouse) {
                command.onMouseMove(mouse.mapPos);
            }
            commandfactory_1.CommandFactory.getInstance().getPoint(cad, "指定点:", drawCallback);
            let mousePos = yield;
            if (mousePos.done == commandfactory_1.CommandStatus.eCancel) {
                command.clear();
                return { value: features, done: commandfactory_1.CommandStatus.eCancel };
            }
            if (mousePos.done == commandfactory_1.CommandStatus.eDone) {
                break;
            }
            let basePos = mousePos.value;
            command.onMouseMove(basePos);
            if (command_param.prompt_angle !== undefined && command_param.prompt_angle == true) {
                function drawAngleCallback(mouse) {
                    let angle = basePos.getAngleTo(mouse.mapPos); //.getAngleTo(basePos);
                    command.setRotate(angle);
                }
                commandfactory_1.CommandFactory.getInstance().getAngle(cad, "输入角度<0>:", drawAngleCallback, basePos);
                let mouse = yield;
                if (mouse.done == commandfactory_1.CommandStatus.eCancel) {
                    command.clear();
                    return { value: features, done: commandfactory_1.CommandStatus.eCancel };
                }
                if (mouse.done == commandfactory_1.CommandStatus.eDone) {
                    break;
                }
                command.setRotate(mouse.value);
            }
            command.onMouseDown(basePos);
            let noSaveFeature = (command_param === null || command_param === void 0 ? void 0 : command_param.noSaveFeature) || false;
            if (noSaveFeature == false) {
                command.save();
                features.push(command.getFeature());
            }
            else {
                features.push(command.getEntiry());
            }
            //是否只绘制一个
            if (command_param.onlyone !== undefined && command_param.onlyone == true) {
                break;
            }
        }
        command.clear();
        return { value: features, done: commandfactory_1.CommandStatus.eOK };
    }
}
